import * as React from "react";
import { Row} from "react-bootstrap";
import { Menu } from '../../definitions/Gateway.dtos';
import { MobileMenuItem } from "./MobileMenuItem";
import { useState } from "react";



const MobileOverlay = (props: { menu: Menu; hideLinks: boolean; isGateway: boolean; hideOverlayMenu: Function}) => {
    const { menu, hideLinks, isGateway } = props;

    const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);

    const hideTopMenus = () => setSubMenuIsOpen(true);

    const showTopMenus = () => setSubMenuIsOpen(false);

    return <>
        <Row className="retirement-menu-dropdown-sm">
            <ul className="list-unstyled">
                {menu.children.map((item, i) => (
                    <li key={i}>
                        <MobileMenuItem
                            item={item}
                            childItems={item.children}
                            hideLinks={hideLinks}
                            isGateway={isGateway}
                            anySubMenuIsOpen={subMenuIsOpen}
                            hideTopMenus={hideTopMenus}
                            showTopMenus={showTopMenus}
                            hideOverlayMenu={() => { props.hideOverlayMenu(); }}
                        />
                    </li>
                ))}
            </ul>
        </Row>
    </>;
}

export { MobileOverlay }