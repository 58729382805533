import { GatewayNavigationProps } from "..";
import { GatewayPath } from "../definitions/GatewayPath";
import { hasGatewayClaim } from "./hasGatewayClaim";

type VisibleHeaderLinks = {
    showRetirement: boolean
    showHealth: boolean
    showWellBeing: boolean
    showLifeAndDisability: boolean
    showSummary: boolean
    showNameLink: boolean
    showMainNavLinks: boolean
}

export function getVisibleHeaderLinks(navContext: GatewayNavigationProps) : VisibleHeaderLinks  {
    const { retirement, lifeAndDisability, health, wellBeing, userInfo} = navContext
    const { claims, isAuthenticated, currentPath } = navContext.gateway

    const isCustSvc = !!userInfo.profileName;  
    const hideMenuWhileLoading = isCustSvc && !retirement.isLoaded;

    const showMainNavLinks= isAuthenticated && !hideMenuWhileLoading &&
        currentPath !== GatewayPath.Challenge &&
        currentPath !== GatewayPath.NeedHelp &&
        currentPath !== GatewayPath.Registration &&
        currentPath !== GatewayPath.Success &&
        currentPath !== GatewayPath.Login &&
        currentPath !== GatewayPath.Logoff;

    const showHealth = health.showHealth
    const showRetirement = retirement.showRetirement
    const showWellBeing = wellBeing.showWellBeing
    const showLifeAndDisability = lifeAndDisability.showLifeAndDisability

    const columnCount = 
        Number(retirement.showRetirement) 
        + Number(health.showHealth) 
        + Number(wellBeing.showWellBeing) 
        + Number(showLifeAndDisability)

    const isFinancialPlanner = hasGatewayClaim(claims, "EY.Search");

    const showSummary = isFinancialPlanner || columnCount > 1;

    const showNameLink = showHealth || showLifeAndDisability || showRetirement || showWellBeing;

    return {
        showRetirement,
        showHealth,
        showWellBeing,
        showLifeAndDisability,
        showSummary,
        showNameLink,
        showMainNavLinks
    }
}