import * as React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useGatewayNavigationContext } from './hooks/useGatewayNavigationContext'
import { RetirementHeaderMenu } from './components/retirementMenu/RetirementHeaderMenu';
import { prettyUrl } from './functions/prettyUrl'
import { NameLink } from './components/NameLink'
import { GatewayPath } from './definitions/GatewayPath'
import { HeaderLink } from './components/HeaderLink'
import { SubMenuHealth } from './components/SubMenuHealth';
import { SubMenuWellBeing } from './components/SubMenuWellBeing';
import { getVisibleHeaderLinks } from './functions/getVisibleHeaderLinks';
import BenefitsAccessLink from './components/BenefitsAccessLink';
import { GatewayLink } from './components/GatewayLink';




const DesktopNavBar : React.FC = () => {
    const context = useGatewayNavigationContext()!
    const {
        rootUrl,
        logout,
        isChallenging,
        isAuthenticated,
        currentPath,
    } = context.gateway

    const { showSummary, showLifeAndDisability, showHealth, showWellBeing, showRetirement, showMainNavLinks } = getVisibleHeaderLinks(context);

    function handleLogout(event: React.MouseEvent) {
        event.preventDefault();
        logout();
    }    

    return <>
        <Navbar sticky="top" className="gateway-main-navigation">
            <Nav className="top-nav">
                <Container className="maxWidth">
                    <Navbar.Brand className="d-inline-flex">
                        <GatewayLink className="d-inline-flex" to={isAuthenticated ? GatewayPath.BenefitsSummary : GatewayPath.Login } >
                            <div className="brand-logo">
                                <img src={prettyUrl(rootUrl, 'images/WP_White_Logo2x.png')} alt="Wespath Logo"/>
                            </div>
                            <div className="brand-text">Benefits Access</div>
                        </GatewayLink>
                    </Navbar.Brand>

                    {showMainNavLinks &&
                        <Navbar.Text className="pr-2 d-inline-flex">

                        {!isChallenging && <>
                            {showRetirement && <><BenefitsAccessLink to={`/mybenefits_pendingactions.aspx`} className="pt-2 nav-link">Pending Actions</BenefitsAccessLink><span className="pt-2">|</span></>}
                            <NameLink />
                        </>}

                        <button type="button" className="btn btn-sm btn-logout" onClick={handleLogout} >Log Out</button>

                    </Navbar.Text>}
                </Container>
            </Nav>

            {showMainNavLinks && 
                <>
                    <Nav className="desktop-links">
                        {showSummary && <HeaderLink to={GatewayPath.BenefitsSummary} text="Summary" />}
                        {showRetirement && <HeaderLink to={GatewayPath.Retirement} text="Retirement" />}
                        {showHealth && <HeaderLink to={GatewayPath.Health} text="Health" isActive={(currentPath === GatewayPath.Health || currentPath === GatewayPath.HealthFlexBenefits)} />}
                        {showWellBeing && <HeaderLink to={GatewayPath.WellBeing} text="Well-Being" />}
                        {showLifeAndDisability && <HeaderLink to={GatewayPath.LifeAndDisability} text="Life & Disability" />}
                    </Nav>

                {currentPath === GatewayPath.Retirement && <RetirementHeaderMenu menu={context.retirement.headerMenu} mobile={false} />}

                {(currentPath === GatewayPath.Health || currentPath === GatewayPath.HealthFlexBenefits) && <SubMenuHealth />}

                {currentPath === GatewayPath.WellBeing && <SubMenuWellBeing />}

                </>
            }

        </Navbar>
    </>
}

export { DesktopNavBar }