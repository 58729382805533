import * as React from "react";
import { GatewayNavigationProps } from ".";

type GatewayNavigationContextType = GatewayNavigationProps

export const GatewayNavigationContext = React.createContext<GatewayNavigationContextType | undefined>(
    undefined
);


export const GatewayNavigationContextProvider = (props: GatewayNavigationProps & {children: React.ReactNode}) => {
    return (
        <GatewayNavigationContext.Provider value={{...props}} >
            {props.children}
        </GatewayNavigationContext.Provider>
    )
} 