import * as React from "react";
import { HeaderMenu } from '../../definitions/Gateway.dtos';
import { HeaderMenuTrigger } from "./HeaderMenuTrigger";
import { Container, Nav, Navbar } from "react-bootstrap";

export type Props = { menu: HeaderMenu; mobile?: boolean };

const RetirementHeaderMenu = (props: Props) => {
    const { menu, mobile } = props;

    const { isGateway } = menu;
    const menuTarget = React.useRef(null);
    const menuClass = mobile ? "retirement-menu-sm" : "retirement-menu-lg";
    return <>
        <Navbar sticky="top" bg="white" className="p-0" style={{ marginBottom: '2em' }}>
            <Nav className={`w-100 ${menuClass}`} >
                <Container ref={menuTarget} className="px-0">
                    {menu?.menus?.map((menu, i) => (
                        <HeaderMenuTrigger container={menuTarget} menu={menu} mobile={!!mobile} active={false} isGateway={isGateway} key={i} />
                    ))}
                </Container>
            </Nav>
        </Navbar>
    </>
}

export { RetirementHeaderMenu }