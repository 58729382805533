import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RetirementHeaderMenu } from './components/retirementMenu/RetirementHeaderMenu';
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { MobileMenuOverlay } from "./components/MobileMenuOverlay";
import { GatewayPath } from "./definitions/GatewayPath";
import { useGatewayNavigationContext } from "./hooks/useGatewayNavigationContext";
import { NameLink } from "./components/NameLink";
import { getVisibleHeaderLinks } from "./functions/getVisibleHeaderLinks";

const MobileNavBar : React.FC = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const context = useGatewayNavigationContext()!;
    const {
        gateway,
        retirement
    } = context
    const { isChallenging, currentPath } = gateway

    const { showMainNavLinks } = getVisibleHeaderLinks(context);

    useEffect(() => {
        if (showMobileMenu && !showMainNavLinks)
            setShowMobileMenu(false);
    }, [showMainNavLinks, setShowMobileMenu, showMobileMenu]);

    return (
        <Navbar sticky="top" className="gateway-main-navigation">
            <Nav className="mob-nav top-nav">
                <Navbar.Brand className="pl-0 d-inline-flex">
                    {showMainNavLinks
                        ? <div className="all-benefits-btn brand-text" onClick={() => { setShowMobileMenu(true); }} >
                            <FontAwesomeIcon icon={faBars} size="lg" style={{ marginRight: '5px', marginBottom:'2px' }} />
                            All Benefits   
                        </div>
                        : <Button className="brand-text" variant="link" onClick={() => { setShowMobileMenu(true); }} disabled={!showMainNavLinks}>
                            Benefits Access
                        </Button>
                    }
                </Navbar.Brand>

                {showMainNavLinks && !isChallenging &&
                    <Navbar.Text className="pr-2 pr-md-5 d-inline-flex">
                        <NameLink />
                    </Navbar.Text>
                }

                {showMobileMenu && <MobileMenuOverlay hideMenu={() => setShowMobileMenu(false)} />}

            </Nav>
            {currentPath === GatewayPath.Retirement && <RetirementHeaderMenu menu={retirement.headerMenu} mobile={true} />}
        </Navbar>
    );
}

export { MobileNavBar }

