import { faArrowCircleDown, faCalculator, faChartLine, faChartPie, faDollarSign, faFileInvoiceDollar, faUserEdit, faUserFriends } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { GatewayPath } from "../definitions/GatewayPath";
import { SubMenu, SubMenuLink } from "./SubMenu";

interface SubMenuRetirementProps {
    HideMenu: Function;
}

const SubMenuRetirement = (props: SubMenuRetirementProps) => {
    const { HideMenu } = props;
    return (
        <SubMenu title="Retirement Shortcuts">
            <SubMenuLink to={GatewayPath.InvestmentsSummary} iconName={faChartLine} text="Investments Overview" hideMenuFunction={HideMenu} />
            <SubMenuLink to={GatewayPath.LifestageInvestmentOptions} iconName={faChartPie} text="LifeStage Investments" hideMenuFunction={HideMenu} />
            <SubMenuLink linkType="baSSO" to={`/mybenefits_statements.aspx`} iconName={faFileInvoiceDollar} text="Account Statements" />
            <SubMenuLink linkType="baSSO" to={`/mybenefits_contributions.aspx`} iconName={faArrowCircleDown} text="Contributions Overview" />
            <SubMenuLink linkType="baSSO" to={`/distribution_options.aspx`} iconName={faDollarSign} text="Distributions Overview" />
            <SubMenuLink to={GatewayPath.AccountManagement} iconName={faUserEdit} text="Personal Information" hideMenuFunction={HideMenu} />
            <SubMenuLink linkType="baSSO" to={`/beneficiaries.aspx`} iconName={faUserFriends} text="Beneficiaries Overview" />
            <SubMenuLink linkType="baSSO" to={`/mybenefits_retirement_projection.aspx`} iconName={faCalculator} text="Projection Tools" />
        </SubMenu>
    );
}

export { SubMenuRetirement }