import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';


const ItemContents = (props: { text: string }) => <>
        {props.text}
        <FontAwesomeIcon icon={faArrowRight} />
</>

const ItemWithArrow = (props: { link: string, text: string, isGateway: boolean; isGatewayLink: boolean; isExternalLink: boolean; hasParent: boolean; hideOverlayMenu: Function}) => {
    const className = props.hasParent ? "retirement-menu-child-link" : "retirement-menu-dropdown-link";

    if (props.isExternalLink) {
        return <a className={className} href={props.link} target="_blank" rel="noopener noreferrer" ><ItemContents text={props.text} /></a>
    }
    else if (props.isGateway) {
        return <Link className={className} to={props.link} onClick={() => { props.hideOverlayMenu();}}><ItemContents text={props.text} /></Link>
    } else {
        return <a className={className} href={props.link}><ItemContents text={props.text} /></a>
    }
};

export { ItemWithArrow }