export function getTimeStamp() {

    let today = new Date();
    let date = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;

    let hours = today.getHours();
    let minutes = today.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    let time = `${hours}:${minutesStr} ${ampm}`;

    return (`${date} ${time}`);
}