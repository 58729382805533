import * as React from 'react';
import { Button } from 'react-bootstrap';
import { prettyUrl } from '../functions/prettyUrl';
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext';

type Props = {
    to: string
    children: any
    buttonLink?: boolean
    className?: string
}

const BenefitsAccessLink = ({
    to,
    className,
    children,
    buttonLink
} : Props) => {

    const {  
        isGateway
    } = useGatewayNavigationContext()!.gateway

    const link = isGateway ? prettyUrl("", to, "BA") : prettyUrl("", to);

    if (buttonLink) {
        return (
            <Button variant="link" className={`${className ?? ""}`} onClick={() => { window.location.href = link }}>
                {children}
            </Button>)
    } else {
        return <a className={`${className ?? ""}`} href={link}>{children}</a>
    }

    
};

export default BenefitsAccessLink
