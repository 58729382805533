import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Navbar, Row } from "react-bootstrap";
import { getTimeStamp } from "./functions/getTimeStamp";
import { prettyUrl } from "./functions/prettyUrl";
import { useGatewayNavigationContext } from "./hooks/useGatewayNavigationContext";

const PrintNavBar = () => {
    const [timeStamp, setTimeStamp] = useState('');
    const { 
        gateway,
        userInfo
    } = useGatewayNavigationContext()!
    const { rootUrl, claims } = gateway
    const { profileName, name } = userInfo
    const isCustSvc = !!profileName;  
    const isFinPlan = !claims.find(c => c === "EY.View");

    useEffect(() => {
        setInterval(() => {
            setTimeStamp(getTimeStamp());
        }, 1000)
    }, [])

    return (
        <Row className="printOnly justify-content-between w-100" >
            <Col className="col-xs-6">
                <Navbar.Brand className="d-inline-flex">
                    <div className="brand-logo">
                        <img src={prettyUrl(rootUrl, 'images/WP_White_Logo2x.png')} alt="Wespath Logo" />
                    </div>
                    <div className="brand-text">Benefits Access</div>
                </Navbar.Brand>
            </Col>
            <Col className="col-xs-6 text-right">
                {name && <div>Created For: {isCustSvc ? profileName : name}</div>}
                {name && <div>Printed By: {(isCustSvc && !isFinPlan) ? "Wespath" : name}</div>}
                {timeStamp}
            </Col>
        </Row>
    )
}

export { PrintNavBar }

    

