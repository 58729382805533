import { faWindowClose } from "@fortawesome/pro-light-svg-icons";
import { faStar, faBadgeDollar, faFileMedical, faHandHoldingSeedling, faSunCloud, faUserCog, faExclamationTriangle, faSignOutAlt} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { GatewayPath } from "../definitions/GatewayPath";
import { getVisibleHeaderLinks } from "../functions/getVisibleHeaderLinks";
import { useGatewayNavigationContext } from "../hooks/useGatewayNavigationContext";
import BenefitsAccessLink from "./BenefitsAccessLink";
import { MobileMenuTile } from "./MobileMenuTile";
import { SubMenuHealth } from "./SubMenuHealth";
import { SubMenuRetirement } from "./SubMenuRetirement";

type Props = { hideMenu: Function }
const MobileMenuOverlay = ({
    hideMenu
} : Props) => {
    const context = useGatewayNavigationContext()!
    const {  
        logout,
        isChallenging,
    } = context.gateway;
    
    const { showSummary, showLifeAndDisability, showHealth, showWellBeing, showRetirement } = getVisibleHeaderLinks(context);
        
    return (
        <div className="menuOverlay" style={{ zIndex: 9999 }}>
        <Container>
                <Row>
                    <Col className="text-right" xs={{ span: 4, offset: 8 }}>
                        <Button variant="link" className="overlay-text overlay-button text-right" onClick={() => hideMenu()}>
                            <FontAwesomeIcon icon={faWindowClose} className="pr-1" size="lg" /> Close
                        </Button>
                    </Col>
                </Row>
                <Row className="overlay-tiles">
                    {showSummary && <MobileMenuTile icon={faStar} path={GatewayPath.BenefitsSummary} hideMenu={() => hideMenu()} >Summary</MobileMenuTile>}
                    {showRetirement && <MobileMenuTile icon={faBadgeDollar} path={GatewayPath.Retirement} hideMenu={() => hideMenu()} >Retirement</MobileMenuTile>}
                    {showHealth && <MobileMenuTile icon={faFileMedical} path={GatewayPath.Health} hideMenu={() => hideMenu()} >Health</MobileMenuTile>}
                    {showWellBeing && <MobileMenuTile icon={faHandHoldingSeedling} path={GatewayPath.WellBeing} hideMenu={() => hideMenu()} >Well-being</MobileMenuTile>}
                    {showLifeAndDisability && <MobileMenuTile icon={faSunCloud} path={GatewayPath.LifeAndDisability} hideMenu={() => hideMenu()} >Life & <br />Disability</MobileMenuTile>}
                    <MobileMenuTile icon={faUserCog} path={GatewayPath.AccountManagement} hideMenu={() => hideMenu()} >Profile</MobileMenuTile>
                </Row>

                <Row>
                    <Col className="col-6 text-nowrap">
                        {!isChallenging && showRetirement && 
                        <BenefitsAccessLink buttonLink className="overlay-text overlay-button text-left" to={`/mybenefits_pendingactions.aspx`}>
                            <FontAwesomeIcon icon={faExclamationTriangle} className="pr-2" size="lg"/>Pending Actions
                        </BenefitsAccessLink>}
                    </Col>
                    <Col className="col-6 text-right">
                        <Button variant="link" className=" overlay-text text-right overlay-button" onClick={() => { logout(); hideMenu(); }}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="pr-2" size="lg" />Log Out
                        </Button>
                    </Col>
                </Row>
                
                {showRetirement &&
                    <Row>
                        <Col>
                            <SubMenuRetirement HideMenu={hideMenu} />    
                        </Col>
                    </Row>
                }
                

                {showHealth &&
                    <Row>
                        <Col>
                            <SubMenuHealth HideMenu={hideMenu} />
                        </Col>
                    </Row>
                }

                {/*showWellBeing && <SubMenuWellBeing />*/}

            
            </Container>
        </div>
    );
}

export { MobileMenuOverlay }