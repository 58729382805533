import * as React from "react";
import { useState } from "react";
import { Col, Overlay } from "react-bootstrap";
import { Menu } from '../../definitions/Gateway.dtos';
import { DesktopOverlay } from "./DesktopOverlay";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconDefinition, faChartLine, faCircle, faFileInvoiceDollar, faBook, faUserCog, faQuestionCircle
} from '@fortawesome/pro-solid-svg-icons';
import { MobileOverlay } from "./MobileOverlay";


function getIcon(text: string) : IconDefinition {
    switch (text) {
        case "Accounts":
            return faChartLine;
        case "Distributions":
            return faFileInvoiceDollar;
        case "Learn":
            return faBook;
        case "Profile":
            return faUserCog;
        case "Help":
            return faQuestionCircle;
        default:
            return faCircle;
    }
}


const TriggerContents = (props: { text: string; mobile: boolean; }) => {
    const { text, mobile } = props;

    if (mobile) {
        return <>
            <div>
                {text}
            </div>
        </>
        
    }
    else {
        return <>
            <div>
                <FontAwesomeIcon icon={getIcon(text)} className="mr-1" size="lg" /> {text}
            </div>
        </>
    }
}

const HeaderMenuTrigger = (props: { menu: Menu; active: boolean; mobile: boolean; isGateway: boolean; container: React.MutableRefObject<null | HTMLDivElement>}) => {
    //const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    //const { isSmallScreen } = useWindowDimensions()
    //const { menu, active, headerData } = props;
    const { menu, isGateway, container, mobile } = props;
    const tile = React.useRef(null);

    function clickTile() {
        if (!isClosing) {
            setShow(true);
        }
    }



    return (
        <>
            <Col ref={tile} className={`retirement-menu-tile ${show ? 'active' : null}`} onClick={clickTile}>
                <div className={`retirement-menu-toggle`}>
                    <div>
                        <TriggerContents text={menu.text} mobile={mobile} />
                    </div>
                </div>
            </Col>
            <Overlay target={mobile ? container.current : tile.current} show={show} placement="bottom" rootClose rootCloseEvent="mousedown" onHide={() => setShow(false)}
                onExiting={() => setIsClosing(true)}
                onExited={() => setIsClosing(false)}
            >
                {({ placement, arrowProps, show: _show, popper, ...props } : any) => (
                    <div 
                        {...props}
                        style={{
                            zIndex: 9999,
                            ...props.style,
                        }}
                    >
                        {mobile ?
                            <MobileOverlay menu={menu} hideLinks={false} isGateway={isGateway} hideOverlayMenu={() => { setShow(false); }} /> :
                            <DesktopOverlay menu={menu} hideLinks={false} isGateway={isGateway} hideOverlayMenu={() => { setShow(false); }} />
                        }
                        
                    </div>
                )}
            </Overlay>
        </>
    )

}

export { HeaderMenuTrigger }