import * as React from 'react';
import { MenuLink } from '../../definitions/Gateway.dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { MobileLink } from './MobileLink';

const MobileSubMenu = (props: { parentText: string; description: string; closeMenu: Function; items?: (MenuLink)[], isGateway: boolean; hideOverlayMenu: Function}) => {
    const { items, isGateway, closeMenu, parentText, description } = props;

    if (!items) {
        return null;
    } else {
        return <>
            <div className="top-link" onClick={() => closeMenu()} >
                <FontAwesomeIcon icon={faArrowLeft} /> {"BACK"}
            </div>
            <div className="top-link parent" >
                {parentText}
                {description ? <p>{description}</p> : null}
            </div>
            <ul>
                {items.map((item, i) => {
                    const { link, text, gatewayLink} = item;

                    return <>
                        <li key={i}>
                            <MobileLink link={link} text={text} isGateway={isGateway} isGatewayLink={gatewayLink} hasParent={true} hideOverlayMenu={() => { props.hideOverlayMenu(); }} />
                        </li>
                    </>
                })}
            </ul>
        </>
    }
}

export { MobileSubMenu }