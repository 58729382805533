import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button, Col } from "react-bootstrap";
import { GatewayPath } from "../definitions/GatewayPath";
import { useGatewayNavigationContext } from "../hooks/useGatewayNavigationContext";
import { GatewayLink } from "./GatewayLink";

type Props = { 
    icon?: IconProp;
    path: GatewayPath | string;
    hideMenu: Function;  
    children?: React.ReactNode;
    isActive?: boolean;
}

const MobileMenuTile = ({
    icon,
    path,
    children,
    hideMenu,
    isActive
} : Props) => {
    const {  
        currentPath
    } = useGatewayNavigationContext()!.gateway;

    const isDisabled = isActive || path === currentPath;

    return (
        <Col xs="4">
            <GatewayLink to={path} className={`shortcutTile ${isDisabled ? "disabled" : ""}`}>
                <Button onClick={() => hideMenu()} disabled={isDisabled}>
                    {icon && <><FontAwesomeIcon icon={icon} className="mb-1" /><br /></>}
                    {children}
                </Button>
            </GatewayLink>
        </Col>
    );
}

export { MobileMenuTile }