import * as React from "react";
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext';
import { prettyUrl } from '../functions/prettyUrl';
import { ServiceProvider } from "../definitions/Gateway.dtos";

type Props = {
    provider: ServiceProvider;
    source: string;
};


const SamlSelfPostForm = React.forwardRef<HTMLFormElement, Props>(
    (props, ref) => {
        const { provider, source } = props;

        let apiRootUrl = process.env.REACT_APP_API_URL;
        var gatewayNavigationProps = useGatewayNavigationContext();

        if (apiRootUrl === undefined ) {
            if (gatewayNavigationProps !== undefined) {
                apiRootUrl = gatewayNavigationProps.gateway.apiRootUrl
            } else {
                apiRootUrl = ''
            }
        }

        return <>
            <form ref={ref} method="POST" action={prettyUrl(apiRootUrl,`SSO/${provider}/${source}`)} target="_blank">
                <input type="hidden" name="contextToken" value={sessionStorage.getItem("PG.ContextToken") ?? ""} />
            </form>
        </>
    }
);

export { SamlSelfPostForm }