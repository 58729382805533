import * as React from 'react';
import { MenuChild, MenuLink } from '../../definitions/Gateway.dtos';
import { MobileSubMenu } from './MobileSubMenu';
import { MobileLink } from './MobileLink';
import { useState } from 'react';

interface IMobileMenuItemProps {
    item: MenuChild;
    childItems?: (MenuLink)[];
    hideLinks: boolean;
    isGateway: boolean;
    anySubMenuIsOpen: boolean;
    showTopMenus: Function;
    hideTopMenus: Function;
    hideOverlayMenu: Function;
}


const MobileMenuItem = (props: IMobileMenuItemProps) => {
    const { link, text, description, gatewayLink } = props.item;
    const { isGateway, hideTopMenus, showTopMenus, anySubMenuIsOpen } = props;

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    if (anySubMenuIsOpen && !menuIsOpen) {
        return null;
    }

    const openMenu = () => {
        setMenuIsOpen(true);
        hideTopMenus();
    }

    const closeMenu = () => {
        setMenuIsOpen(false);
        showTopMenus();
    }




    if (link) {
        return <MobileLink link={link} text={text} isGateway={isGateway} isGatewayLink={gatewayLink} hasParent={false} hideOverlayMenu={() => { props.hideOverlayMenu(); }}/>
    } else {
        if (menuIsOpen) {
            return <>
                <MobileSubMenu parentText={text} items={props.childItems} isGateway={isGateway} closeMenu={closeMenu} description={description} hideOverlayMenu={() => { props.hideOverlayMenu(); }} /> 
            </>
        } else {
            return <div className="top-link" onClick={() => openMenu()}> {text}</div>
        }

    }
}

export { MobileMenuItem };