import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    const isSmallScreen = width < 768;
    const isMediumScreen = width < 992;
    const isLargeScreen = width >= 992 && width < 1200;
    const isLargeScreenOrSmaller = isLargeScreen || isMediumScreen;
    return {
        width,
        height,
        isSmallScreen,
        isMediumScreen,
        isLargeScreen,
        isLargeScreenOrSmaller
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}