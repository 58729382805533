import * as React from "react";
import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { ServiceProvider } from "../definitions/Gateway.dtos";
import { SamlSelfPostForm } from "./SamlSelfPostForm";


type Props = { 
    isShowing: boolean 
    hideModal: () => void
    serviceProvider: ServiceProvider
    username: string
    referrer?: string
}
const ExternalLinkModal = ({
    isShowing,
    hideModal,
    serviceProvider,
    username,
    referrer
}: Props) => {

    const formRef = useRef<HTMLFormElement>(null);

    const onClickCancel = () => {
        hideModal();
    }

    const onClickContinue = () => {
        formRef?.current?.submit();
        sessionStorage.setItem(`${username}-sso-${serviceProvider}`, "ssoSent");
        hideModal();
    }

    return (<>
        <Modal show={isShowing} backdrop="static" centered>
            <Modal.Body>
                You are going to a different website
                <br/><br/>
                You are about to visit a website outside of Wespath's Benefits Access. Please familiarize yourself with the Privacy Policy and Terms and Conditions of Use as it is different than Wespath’s.
            </Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button variant="primary" className="mt-3 mr-3" onClick={onClickContinue}>Continue</Button>
                <Button variant="link" className="mt-3" onClick={onClickCancel}>Stay on Wespath's website</Button>
            </Modal.Footer>
        </Modal>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source={referrer ??= ''} />
    </>);
};

export { ExternalLinkModal }