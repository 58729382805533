import * as React from 'react';
import { MenuChild, MenuLink } from '../../definitions/Gateway.dtos';
import { DesktopSubMenu } from './DesktopSubMenu';
import { ItemWithArrow } from './ItemWithArrow';

const DesktopMenuItem = (props: { item: MenuChild; childItems?: (MenuLink)[]; hideLinks: boolean; isGateway: boolean; hideOverlayMenu: Function}) => {
    const { link, text, description, gatewayLink, externalLink } = props.item;
    const { isGateway } = props;


    if (link) {
        return <ItemWithArrow link={link} text={text} isGateway={isGateway} isGatewayLink={gatewayLink} isExternalLink={externalLink} hasParent={false} hideOverlayMenu={() => { props.hideOverlayMenu(); }} />
    } else {
        return <>
            <div className="retirement-menu-dropdown-title" > {text}</div>
            {description &&
                <div className="retirement-menu-dropdown-description">{description}</div>
            }
            <DesktopSubMenu items={props.childItems} isGateway={isGateway} hideOverlayMenu={() => { props.hideOverlayMenu(); }} />
        </>
    }
}

export { DesktopMenuItem };