import * as React from 'react';
import { Navbar, Nav, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import BenefitsAccessLink from './BenefitsAccessLink';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext';
import { prettyUrl } from '../functions/prettyUrl';

const SubMenu = (props: { title: string; minorTitle?: string; children: any }) => {
    const { isSmallScreen } = useWindowDimensions();

    if (isSmallScreen) {
        return (
            <div className="sub-nav-mobile">
                <div className="overlay-text title">
                    {props.title} {props.minorTitle && <span className="subTitle">{props.minorTitle}</span>}
                </div>
                <ul className="list-unstyled">
                    {props.children}
                </ul>
            </div>
        )    
    } else {
        return (
            <Navbar sticky="top" bg="white" className="p-0" style={{ marginBottom: '2em' }}>
                <Nav className="w-100 sub-menu-lg">
                    <Container className="px-0">
                        {props.children}
                    </Container>
                </Nav>
            </Navbar>
        )
    }

};
export { SubMenu };

interface SubMenuLinkProps {
    to?: string;
    iconName?: FontAwesomeIconProps["icon"];
    iconName2?: FontAwesomeIconProps["icon"];
    linkType?: null | "baSSO" | "external";
    suppressIconOnSmallScreen?: boolean;
    text?: string;
    desktopText?: string | JSX.Element;
    tabletText?: string | JSX.Element;
    disabled?: boolean;
    desktopStyle?: React.CSSProperties | undefined;
    ClickHandler?: () => void;
    hideMenuFunction?: Function;
}
const SubMenuLink = (props: SubMenuLinkProps) => {
    const { isSmallScreen, isLargeScreenOrSmaller } = useWindowDimensions();
    const {
        rootUrl,
        isGateway,
        currentPath
    } = useGatewayNavigationContext()!.gateway;


    

    const isActiveMenu = props.to === currentPath
    const propsTo = props.to ? props.to : ''
    const isExternal = props.linkType === "external"
    const isBaSso = props.linkType === "baSSO"

    const LinkContent = () => {
        return <LinkContents isLargeScreenOrSmaller={isLargeScreenOrSmaller} isSmallScreen={isSmallScreen} pageData={props} isExternal={isExternal} />
    }

    const LinkWrapper = () => {

        if (props.disabled) {
            return <div className="disabledLink">
                <LinkContent />
            </div>
        }

        else if (isActiveMenu && !isSmallScreen) {
            return <div>
                <LinkContent />
            </div>
        }

        else if (isExternal) {
            return <a href={props.to} target="_blank" rel="noopener noreferrer" onClick={props.ClickHandler}>
                <LinkContent />
            </a>
        }

        else if (isBaSso) {
            return <BenefitsAccessLink to={propsTo}>
                <LinkContent />
            </BenefitsAccessLink>
        }

        else if (props.linkType === null || props.linkType === undefined) {

            if (isGateway || !isSmallScreen) {
                return <Link to={propsTo} onClick={() => { isSmallScreen ? (props.hideMenuFunction && props.hideMenuFunction()) : null }}>
                    <LinkContent />
                </Link>
            } else {
                return <a href={prettyUrl(rootUrl, (propsTo))}>
                    <LinkContent />
                </a>
            }
        }

        else {
            return <></>
        }

    }


    if (isSmallScreen) {
        return (
            <li className="overlay-text">
                <LinkWrapper />
            </li>
        )
    } else {
        return (<>
            <Col style={props.desktopStyle} className={`sub-menu-tile`}>
                <div className={`sub-menu-tile-inner ${!props.iconName && !props.iconName2 && 'no-icon'} ${isActiveMenu && 'isActive'}`}>
                    <div>
                        <LinkWrapper />
                    </div>
                </div>
            </Col>
        </>)
    }

    
};
export { SubMenuLink };

interface LinkContentsProps { pageData: SubMenuLinkProps; isExternal?: boolean; isSmallScreen: boolean; isLargeScreenOrSmaller?: boolean }
const LinkContents = (props: LinkContentsProps) => {
    const { text, desktopText, tabletText, iconName, iconName2, suppressIconOnSmallScreen } = props.pageData

    if (!props.isSmallScreen) {
        let formattedText: string | JSX.Element | undefined = text;

        if (props.isLargeScreenOrSmaller && tabletText) {
            formattedText = tabletText;
        } else if (desktopText) {
            formattedText = desktopText;
        }

        return (
            <table style={{ height: "100%" }} >
                <tbody>
                    <tr className="text-center">
                        <td className="text-right pr-2">
                            {iconName && <FontAwesomeIcon size="lg" icon={iconName} /> }
                            {iconName2 && <FontAwesomeIcon className="pl-1" size="lg" icon={iconName2} /> }
                        </td>
                        <td className="text-left">
                            {formattedText}
                            {props.isExternal && <span className="d-md-none mobile pl-2">
                                <FontAwesomeIcon icon={faExternalLink} />
                            </span>}
                        </td>
                    </tr>
                </tbody>
            </table>  
        )
    } else {
        const showIcon = !suppressIconOnSmallScreen;

        return (
            <Row className="w-100">
                {showIcon && <Col xs="2" className="pr-0">
                    {iconName &&
                        <FontAwesomeIcon size="1x" icon={iconName} />
                    }

                    {iconName2 &&
                        <FontAwesomeIcon className="pl-1" size="1x" icon={iconName2} />
                    }
                </Col>}
                <Col className={`text-nowrap ${showIcon ? 'px-0' : 'pr-0'}`}>
                    {text}
                    {props.isExternal && <FontAwesomeIcon size="1x" className="pl-2" icon={faExternalLink} />}
                </Col>
            </Row>
            
        )
    }
        
}

