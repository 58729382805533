import * as React from 'react'
import { GatewayPath } from '../definitions/GatewayPath';
import { titleCase } from '../functions/titleCase';
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { getVisibleHeaderLinks } from '../functions/getVisibleHeaderLinks';
import { GatewayLink } from './GatewayLink';

export const NameLink = () => {
    const context = useGatewayNavigationContext()!; 
    const { 
        name,
        firstName,
        profileName,
        profileFirstName,
    } = context.userInfo

    const { showNameLink } = getVisibleHeaderLinks(context);

    const isCustSvc = !!profileName;    

    return (
        showNameLink
        ? 
        <>
            {isCustSvc && <>
                <span className="d-none d-lg-inline mx-2 pt-2">{name && titleCase(name)} for: </span>
            </>}
            
            <GatewayLink to={GatewayPath.AccountManagement} className="mr-0 mr-md-3 nav-link text-nowrap">
                <span className="d-none d-lg-inline">{isCustSvc ? profileName : name}</span>
                <span className="d-inline d-lg-none">{isCustSvc ? profileFirstName : firstName}</span>
                <FontAwesomeIcon size="lg" icon={faUserCircle} className="ml-1" />
            </GatewayLink>
            
        </>
        :
        null
    )
}