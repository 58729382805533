export enum GatewayPath {
    BenefitsSummary = "/summary",
    Health = "/health",
    LifeAndDisability = "/lifeanddisability",
    Login = "/login",
    Logoff = "/logoff",
    Registration = "/registration",
    Retirement = "/retirement",
    NeedHelp = "/needhelp",
    WellBeing = "/wellbeing",
    AccessDenied = "/accessdenied",
    Success = "/success",
    Challenge = "/challenge",
    AccountManagement = "/accountmanagement",
    SSO = "/SSO",
    BapInboundSso = "/BapInbound",
    Maintenance = "/maintenance",
    SiteMap = "/sitemap",
    LsriOverview = "/LSRI/ProgramOverview",
    LsriSocialSecurity = "/LSRI/SocialSecurity",
    LsriMpp = "/LSRI/Mpp",
    LsriFunding = "/LSRI/Funding",
    LsriBridge = "/LSRI/Bridge",
    LsriQlac = "/LSRI/Qlac",
    LsriReview = "/LSRI/Review",
    LsriPaymentOptions = "/LSRI/PaymentOptions",
    LsriBankInfo = "/LSRI/BankInformation",
    LsriTaxOptions = "/LSRI/TaxOptions",
    LsriVerify = "/LSRI/Verify",
    LsriSuccess = "/LSRI/Success",
    LsriSummary = "/LSRI/Summary",
    InvestmentsSummary = "/investmentsSummary",
    LifestageInvestmentOptions = "lifeStageInvestmentOptions",
    HealthFlexBenefits = "/HealthFlexBenefits",
    ManageDirectDeposit = "/manageDirectDeposit",
    UpdateBankInformation = "/updateBankInformation",
    PaymentInfo = "/paymentsInfo"
}
