/* Options:
Date: 2023-10-17 15:33:59
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44314

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: DownloadDocument
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export class Accountowner
{
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public namePfx: string;
    public nameSfx: string;
    public businessName: string;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public zip: string;
    public ssn: string;
    public dob: string;
    public idType: string;
    public idNo: string;
    public idState: string;

    public constructor(init?: Partial<Accountowner>) { (Object as any).assign(this, init); }
}

export enum PhoneType
{
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    MOBILE = 'MOBILE',
}

export enum AuthentifyDeliveryMethod
{
    Text = 'Text',
    Voice = 'Voice',
}

export enum ChallengeType
{
    LoginHelp = 'LoginHelp',
    Transaction = 'Transaction',
    Login = 'Login',
}

export enum PasswordOrigType
{
    ACCT_MAINTENANCE = 'ACCT_MAINTENANCE',
    NEEDHELP = 'NEEDHELP',
}

export enum Ruleset
{
    KBA = 'KBA',
    OTP = 'OTP',
}

export enum CallType
{
    TEXT = 'TEXT',
    VOICE = 'VOICE',
}

export enum LexisNexisPhoneType
{
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    MOBILE = 'MOBILE',
}

export class LexisNexisParticipant
{
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public birthDate: string;
    public address: string;
    public ssn: string;
    public ruleset: Ruleset;
    public phoneNumber: string;
    public otpDeliveryInfo: CallType;
    public userName: string;
    public participantNumber: string;
    public callerType: string;
    public provider: string;
    public threatMetrixSessionId: string;
    public requestKey: string;
    public page: string;
    public phoneType: LexisNexisPhoneType;

    public constructor(init?: Partial<LexisNexisParticipant>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class AddRequestInstanceResponse
{
    public requestId: number;
    public requestIntanceId: number;
    public message: string;
    public status: string;
    public userCreated: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AddRequestInstanceResponse>) { (Object as any).assign(this, init); }
}

export class LexisNexisOTP
{
    public passCode: string;
    public conversationId: string;
    public addReqInstResp: AddRequestInstanceResponse;
    public reqInstDetailId: number;
    public participantNumber: string;
    public username: string;
    public requestKey: string;

    public constructor(init?: Partial<LexisNexisOTP>) { (Object as any).assign(this, init); }
}

export class LexisNexisKBA
{
    public conversationId: string;
    public questionSetId: number;
    public qAs: QuestionAnswer[];
    public addReqInstResp: AddRequestInstanceResponse;
    public reqInstDetailId: number;
    public participantNumber: string;
    public username: string;
    public requestKey: string;

    public constructor(init?: Partial<LexisNexisKBA>) { (Object as any).assign(this, init); }
}

export enum RequestType
{
    REGISTRATION = 'REGISTRATION',
    NEEDHELP = 'NEEDHELP',
}

export enum LsriStage
{
    Setup = 'Setup',
    Manage = 'Manage',
    Commit = 'Commit',
    Pending = 'Pending',
}

export enum LsriSocSecStatus
{
    NoInfo = 'NoInfo',
    Planning = 'Planning',
    Receiving = 'Receiving',
    OptOut = 'OptOut',
    Ineligible = 'Ineligible',
}

export enum LsriQlacFormOfBenefit
{
    Single = 'Single',
    Joint70 = 'Joint70',
}

export class LsriStageCalculations
{
    public lifeStage: number;
    public socialSecurity: number;
    public mpp: number;
    public qlac: number;

    public constructor(init?: Partial<LsriStageCalculations>) { (Object as any).assign(this, init); }
}

export class OracleCalculation
{
    public elected: boolean;
    public scenario: string;
    public includeMpp: boolean;
    public availBalance: number;
    public pctAllocated: number;
    public amtAllocated: number;
    public lastSync: string;
    public ssAge: number;
    public qlacAge: number;
    public bridgePool: number;
    public bridgeQuote: number;
    public bridgeEndDate: string;
    public ssDate: string;
    public qlacDate: string;
    public qlacPerc: string;
    public qlacPool: string;
    public qlacFoB: LsriQlacFormOfBenefit;
    public lsriStart: LsriStageCalculations;
    public socSecStart: LsriStageCalculations;
    public qlacStart: LsriStageCalculations;
    public end: LsriStageCalculations;
    public lsriLifestage: number;
    public lsriSs: number;
    public lsriMpp: number;
    public ssLifestage: number;
    public ssSs: number;
    public ssMpp: number;
    public qlacLifestage: number;
    public qlacSs: number;
    public qlacQlac: number;
    public qlacMpp: number;
    public endSs: number;
    public endLifestage: number;
    public endQlac: number;
    public endMpp: number;

    public constructor(init?: Partial<OracleCalculation>) { (Object as any).assign(this, init); }
}

export enum BankAccountType
{
    Checking = 'Checking',
    Savings = 'Savings',
}

export class BankAccount
{
    public bankName: string;
    public bankAccountType?: BankAccountType;
    public routingNumber: string;
    public accountNumber: string;
    public bankOwnership: string;
    public originalRoutingNumber: string;
    public originalAccountNumber: string;

    public constructor(init?: Partial<BankAccount>) { (Object as any).assign(this, init); }
}

export enum FederalWithholdingOption
{
    DoNotWithhold = 'DoNotWithhold',
    Withhold = 'Withhold',
}

export enum FedMaritalStatus
{
    Unknown = 'Unknown',
    Single = 'Single',
    Married = 'Married',
    HeadOfHousehold = 'HeadOfHousehold',
}

export enum Pre2022FedMaritalStatus
{
    Pre2022Single = 'Pre2022Single',
    Pre2022Married = 'Pre2022Married',
    Pre2022MarriedHigherRate = 'Pre2022MarriedHigherRate',
}

export class TaxOptions
{
    public federalWithholding?: FederalWithholdingOption;
    public pre2022FederalWithholding?: FederalWithholdingOption;
    public stateOfResidence: string;
    public fedMaritalStatus?: FedMaritalStatus;
    public pre2022FedMaritalStatus?: Pre2022FedMaritalStatus;
    public numberOfAllowances?: number;
    public additionalAmount?: number;
    public taxableAnnualPay: string;
    public otherTaxablePay: string;
    public dependentChildrenCredits: string;
    public dependentOtherCredits: string;
    public otherOptionalCredits: string;
    public otherIncome: string;
    public deductions: string;
    public extraWithholding: string;
    public isW4POnFile: boolean;

    public constructor(init?: Partial<TaxOptions>) { (Object as any).assign(this, init); }
}

export enum ServiceProvider
{
    None = 'None',
    BCBS = 'BCBS',
    BusinesSolver = 'BusinesSolver',
    Optum = 'Optum',
    Quest = 'Quest',
    UHC = 'UHC',
    VirginPulse = 'VirginPulse',
    WageWorks = 'WageWorks',
    MDLive = 'MDLive',
    Cigna = 'Cigna',
    Salesforce = 'Salesforce',
    Quantum = 'Quantum',
}

export class ExternalSso
{
    public serviceProvider: ServiceProvider;
    public source: string;
    public contextToken: string;

    public constructor(init?: Partial<ExternalSso>) { (Object as any).assign(this, init); }
}

export class Primaryinstitution
{
    public address: string;
    public city: string;
    public country: string;
    public name: string;
    public phone: string;
    public postalCode: string;
    public state: string;

    public constructor(init?: Partial<Primaryinstitution>) { (Object as any).assign(this, init); }
}

export class Phone
{
    public number: string;
    public type: PhoneType;
    public isForeign: boolean;

    public constructor(init?: Partial<Phone>) { (Object as any).assign(this, init); }
}

export class BenefitsAccessSession
{
    public firstName: string;
    public lastName: string;
    public name: string;
    public profileFirstName: string;
    public profileName: string;
    public participantNumber: string;
    public twoFactorComplete: boolean;
    public sessionId: string;
    public userName: string;
    public usernameForOracle: string;
    public ssn: string;
    public role: string;
    public claims: string[];
    public showLifeAndDisability: boolean;
    public showWellBeing: boolean;
    public showHealth: boolean;
    public planElections: PlanElection[];
    public hasHsa: boolean;
    public hasMedical: boolean;
    public hasVp: boolean;
    public phone: string;
    public altPhone: string;
    public textAlertPhone: string;
    public gatewayAPIURL: string;

    public constructor(init?: Partial<BenefitsAccessSession>) { (Object as any).assign(this, init); }
}

export class HeaderMenu
{
    public isGateway: boolean;
    public menus: Menu[];

    public constructor(init?: Partial<HeaderMenu>) { (Object as any).assign(this, init); }
}

export class ParticipantItrStatus
{
    public itrStatus: string;
    public gbgmServiceIndicator: string;

    public constructor(init?: Partial<ParticipantItrStatus>) { (Object as any).assign(this, init); }
}

export class ParticipantDocumentType
{
    public docTypeName: string;
    public docTypeId: number;
    public documents: Document[];

    public constructor(init?: Partial<ParticipantDocumentType>) { (Object as any).assign(this, init); }
}

export enum HsaPlanType
{
    HSA = 'HSA',
    FSA = 'FSA',
    DCRA = 'DCRA',
    HRA = 'HRA',
}

export class HealthSavingsAccount
{
    public hsaPlanType: HsaPlanType;
    public displayName: string;
    public amount: number;

    public constructor(init?: Partial<HealthSavingsAccount>) { (Object as any).assign(this, init); }
}

export enum PlanType
{
    MEDICAL = 'MEDICAL',
    PHARMACY = 'PHARMACY',
    DENTAL = 'DENTAL',
    VISION = 'VISION',
}

export class PlanElection
{
    public type: PlanType;
    public provider: string;
    public plan: string;
    public tier: string;
    public obligations: Obligation[];

    public constructor(init?: Partial<PlanElection>) { (Object as any).assign(this, init); }
}

export class InvestmentSummaryPlanDetails
{
    public tooltip: string;
    public link: string;
    public name: string;

    public constructor(init?: Partial<InvestmentSummaryPlanDetails>) { (Object as any).assign(this, init); }
}

export class InvestmentSummaryDetails
{
    public ordinal: string;
    public tooltip: string;
    public link: string;
    public name: string;
    public plan: PlanDetails[];
    public total: number;

    public constructor(init?: Partial<InvestmentSummaryDetails>) { (Object as any).assign(this, init); }
}

export class InvestmentSummaryTotalDetails
{
    public name: string;
    public total: number;

    public constructor(init?: Partial<InvestmentSummaryTotalDetails>) { (Object as any).assign(this, init); }
}

export class PlanGroupOptionDetails
{
    public ordinal: string;
    public color: string;
    public ratio: number;
    public tooltip: string;
    public link: string;
    public name: string;
    public price: number;
    public units: number;
    public balance: number;

    public constructor(init?: Partial<PlanGroupOptionDetails>) { (Object as any).assign(this, init); }
}

export class NetRateofReturnDetail
{
    public ordinal: string;
    public amount: string;

    public constructor(init?: Partial<NetRateofReturnDetail>) { (Object as any).assign(this, init); }
}

export class CmsFragment
{
    public name: string;
    public html: string;

    public constructor(init?: Partial<CmsFragment>) { (Object as any).assign(this, init); }
}

export class FundGroup
{
    public groupName: string;
    public funds: Fund[];

    public constructor(init?: Partial<FundGroup>) { (Object as any).assign(this, init); }
}

export class QuestionSet
{
    public conversationId: string;
    public questionSetId: number;
    public questions: QuestionInfo[];

    public constructor(init?: Partial<QuestionSet>) { (Object as any).assign(this, init); }
}

export class LexisNexisTransactionResponse
{
    public questionsets: QuestionSet;
    public transactionStatus: string;
    public conversationId: string;
    public requestId: string;
    public actionType: string;
    public message: string;
    public isFraud: boolean;
    public addReqInstResp: AddRequestInstanceResponse;
    public reqInstDetailId: number;

    public constructor(init?: Partial<LexisNexisTransactionResponse>) { (Object as any).assign(this, init); }
}

export class GenericOracleResponse
{
    public errors: OracleError[];

    public constructor(init?: Partial<GenericOracleResponse>) { (Object as any).assign(this, init); }
}

export class OracleGetLogin extends GenericOracleResponse
{
    public adUsername: string;
    public name: string;
    public firstName: string;
    public lastName: string;
    public partNum: string;
    public ssn: string;
    public electronicDelivery: string;
    public email: string;
    public streetAddress1: string;
    public streetAddress2: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public countryCode: string;
    public phone: string;
    public altPhone: string;
    public textPhone: string;
    public lockedFlag: string;
    public locked: boolean;
    public unlocked: boolean;
    public code: string;
    public identityChallenge: boolean;
    public fraudAlert: boolean;
    public personId: string;
    public userId: string;
    public showBeneMsg: boolean;
    public isRegistered: boolean;

    public constructor(init?: Partial<OracleGetLogin>) { super(init); (Object as any).assign(this, init); }
}

export class OmniStateList
{
    public states: OmniState[];

    public constructor(init?: Partial<OmniStateList>) { (Object as any).assign(this, init); }
}

export class LsriFundingPlan
{
    public planName: string;
    public display: string;
    public amount: number;
    public hasPendingAction: boolean;

    public constructor(init?: Partial<LsriFundingPlan>) { (Object as any).assign(this, init); }
}

export class PrGetLsriInfoResponse
{
    public ss_Status?: LsriSocSecStatus;
    public ss_Amt?: number;
    public ss_Min_Amt: number;
    public ss_Max_Amt: number;
    public age: number;
    public yos: number;
    public itr_Date: string;
    public qlacAgeRestrict: boolean;

    public constructor(init?: Partial<PrGetLsriInfoResponse>) { (Object as any).assign(this, init); }
}

export enum LsriSelectionClearedFlag
{
    N = 'N',
    I = 'I',
    C = 'C',
}

export class OracleSocSec
{
    public ss_Status?: LsriSocSecStatus;
    public ss_Amt?: number;
    public retAgeStr: string;
    public retAge: number;
    public last_Updated: string;
    public commitDate: string;
    public selectionCleared: LsriSelectionClearedFlag;
    public lsriPartId: number;
    public ownerVersion: string;
    public minPaymentAmt: number;
    public partFlag: string;

    public constructor(init?: Partial<OracleSocSec>) { (Object as any).assign(this, init); }
}

export class OracleVisitedSteps
{
    public socialSecurity: boolean;
    public mpp: boolean;
    public funding: boolean;
    public bridge: boolean;
    public qlac: boolean;
    public review: boolean;
    public payment: boolean;
    public bank: boolean;
    public tax: boolean;
    public verify: boolean;

    public constructor(init?: Partial<OracleVisitedSteps>) { (Object as any).assign(this, init); }
}

export enum MatrixVersionType
{
    System = 'System',
    User = 'User',
}

export class OracleCalculationCommon
{
    public includeMpp: boolean;
    public availBalance: number;
    public pctAllocated: number;
    public amtAllocated: number;
    public versionType: MatrixVersionType;
    public qlacDate: string;
    public qlacAge: number;
    public lsriCommAge: number;
    public qlacExceedFlag: string;
    public qlacFoB: LsriQlacFormOfBenefit;
    public spsToOmni: boolean;
    public lsriCommDate: string;

    public constructor(init?: Partial<OracleCalculationCommon>) { (Object as any).assign(this, init); }
}

export class OracleLsriElection
{
    public socialSecurity: OracleSocSec;
    public visitedSteps: OracleVisitedSteps;
    public paymentOptions: LsriPaymentOption[];
    public systemDefault: OracleCalculation;
    public matrix: OracleCalculation[];
    public matrixCommon: OracleCalculationCommon;
    public partDoesNotExist: boolean;

    public constructor(init?: Partial<OracleLsriElection>) { (Object as any).assign(this, init); }
}

export class Messages
{
    public type: string;
    public date: string;
    public adjustedPmt: string;

    public constructor(init?: Partial<Messages>) { (Object as any).assign(this, init); }
}

export class OracleUpdateLSRIAgeQlacBridgeResponse
{
    public selection_Type: string;
    public partDoesNotExist: boolean;

    public constructor(init?: Partial<OracleUpdateLSRIAgeQlacBridgeResponse>) { (Object as any).assign(this, init); }
}

export class State
{
    public value: string;
    public display: string;

    public constructor(init?: Partial<State>) { (Object as any).assign(this, init); }
}

export class OracleStateList extends GenericOracleResponse
{
    public states: State[];

    public constructor(init?: Partial<OracleStateList>) { super(init); (Object as any).assign(this, init); }
}

export class Country
{
    public value: string;
    public display: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class OracleCountryList extends GenericOracleResponse
{
    public countries: Country[];

    public constructor(init?: Partial<OracleCountryList>) { super(init); (Object as any).assign(this, init); }
}

export class Allocation
{
    public allocAmt: number;
    public allocPct: number;
    public fund: string;

    public constructor(init?: Partial<Allocation>) { (Object as any).assign(this, init); }
}

export class Installment
{
    public duration: number;
    public frequency: string;
    public nextPayDate: string;
    public payment: number;
    public type: string;

    public constructor(init?: Partial<Installment>) { (Object as any).assign(this, init); }
}

export class Investment
{
    public name: string;
    public lifestage: boolean;
    public risk: string;
    public socialSecElig: boolean;
    public asOfDate: string;
    public ratesOfReturn: RateOfReturn[];

    public constructor(init?: Partial<Investment>) { (Object as any).assign(this, init); }
}

export class Transaction
{
    public plan: string;
    public type: string;
    public subType: string;
    public date: string;
    public amount: number;

    public constructor(init?: Partial<Transaction>) { (Object as any).assign(this, init); }
}

export class SelfManagedRetirementMessage
{
    public messageText: string;

    public constructor(init?: Partial<SelfManagedRetirementMessage>) { (Object as any).assign(this, init); }
}

export class QuestionAnswer
{
    public questionId: number;
    public choices: ChoiceID[];

    public constructor(init?: Partial<QuestionAnswer>) { (Object as any).assign(this, init); }
}

export class Menu
{
    public text: string;
    public children: MenuChild[];
    public alignRight: boolean;
    public offset: string;

    public constructor(init?: Partial<Menu>) { (Object as any).assign(this, init); }
}

export class Document
{
    public participantDocumentId: number;
    public guid: string;
    public storeName: string;
    public displayName: string;
    public beginDate: string;
    public endDate: string;

    public constructor(init?: Partial<Document>) { (Object as any).assign(this, init); }
}

export class Obligation
{
    public label: string;
    public value: string;

    public constructor(init?: Partial<Obligation>) { (Object as any).assign(this, init); }
}

export class PlanDetails
{
    public name: string;
    public total: number;

    public constructor(init?: Partial<PlanDetails>) { (Object as any).assign(this, init); }
}

export class Fund
{
    public name: string;
    public allocation: string;
    public href: string;
    public title: string;
    public color: string;

    public constructor(init?: Partial<Fund>) { (Object as any).assign(this, init); }
}

export class OracleError
{
    public code: number;
    public message: string;

    public constructor(init?: Partial<OracleError>) { (Object as any).assign(this, init); }
}

export class OmniState
{
    public value: string;
    public display: string;
    public withholdingFlag: boolean;
    public default: boolean;

    public constructor(init?: Partial<OmniState>) { (Object as any).assign(this, init); }
}

export class LsriPaymentOption
{
    public date: string;
    public elected: boolean;

    public constructor(init?: Partial<LsriPaymentOption>) { (Object as any).assign(this, init); }
}

export class RateOfReturn
{
    public label: string;
    public rate?: number;

    public constructor(init?: Partial<RateOfReturn>) { (Object as any).assign(this, init); }
}

export class ChoiceID
{
    public choice: number;

    public constructor(init?: Partial<ChoiceID>) { (Object as any).assign(this, init); }
}

export class MenuLink
{
    public gatewayLink: boolean;
    public externalLink: boolean;
    public text: string;
    public link: string;

    public constructor(init?: Partial<MenuLink>) { (Object as any).assign(this, init); }
}

export class MenuChild extends MenuLink
{
    public description: string;
    public column: number;
    public children: MenuLink[];

    public constructor(init?: Partial<MenuChild>) { super(init); (Object as any).assign(this, init); }
}

export class QuestionInfo
{
    public questionText: string;
    public questionID: number;
    public answers: Choice[];
    public choiceId: number;

    public constructor(init?: Partial<QuestionInfo>) { (Object as any).assign(this, init); }
}

export class Text1
{
    public statement: string;

    public constructor(init?: Partial<Text1>) { (Object as any).assign(this, init); }
}

export class Choice
{
    public choiceId: number;
    public text: Text1;

    public constructor(init?: Partial<Choice>) { (Object as any).assign(this, init); }
}

export class VerifyLast4SsnResponse
{
    public success: boolean;
    public lock: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<VerifyLast4SsnResponse>) { (Object as any).assign(this, init); }
}

export class AccountOwnershipResponse
{
    public errorMessage: Object;
    public statusCode: number;
    public denyCt: number;
    public statusDescription: string;
    public primaryInstitution: Primaryinstitution;
    public validRtn: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AccountOwnershipResponse>) { (Object as any).assign(this, init); }
}

export class LogActivityResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<LogActivityResponse>) { (Object as any).assign(this, init); }
}

export class GetApplicationTimeoutResponse
{
    public logoffMinutes: number;
    public logoffWarningMinutes: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApplicationTimeoutResponse>) { (Object as any).assign(this, init); }
}

export class GenerateNewAuthKeysResponse
{
    public privateKeyXml: string;
    public publicKeyXml: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GenerateNewAuthKeysResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthPhonesResponse
{
    public phones: Phone[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAuthPhonesResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthStatusResponse
{
    public twoFactorComplete: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAuthStatusResponse>) { (Object as any).assign(this, init); }
}

export class SendAuthCodeResponse
{
    public success: boolean;
    public disableNumber: boolean;
    public criticalFailure: boolean;
    public accessDenied: boolean;
    public maxCodesSent: boolean;
    public expireTime: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendAuthCodeResponse>) { (Object as any).assign(this, init); }
}

export class ValidateCodeResponse
{
    public codeCorrect: boolean;
    public expired: boolean;
    public lastAttempt: boolean;
    public accessDenied: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ValidateCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetBenefitsAccessPlanSponsorsSsoUserInfoResponse
{
    public name: string;
    public firstName: string;
    public lastName: string;
    public profileName: string;
    public profileFirstName: string;
    public profileLastName: string;
    public profileUsername: string;
    public username: string;
    public participantNumber: string;
    public isLocked: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBenefitsAccessPlanSponsorsSsoUserInfoResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonalInformationResponse
{
    public name: string;
    public username: string;
    public firstName: string;
    public lastName: string;
    public partNum: string;
    public ssn: string;
    public personId: string;
    public dob: string;
    public gender: string;
    public maritalStatus: string;
    public marriageDate: string;
    public spouseName: string;
    public spouseFirstName: string;
    public spouseMiddleName: string;
    public spouseLastName: string;
    public spouseGender: string;
    public spouseDob: string;
    public spouseSsn: string;
    public phone: string;
    public altPhone: string;
    public textPhone: string;
    public email: string;
    public streetAddress1: string;
    public streetAddress2: string;
    public streetAddress3: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public countryCode: string;
    public context: string;
    public electronicDelivery: string;
    public eyAuthorization: string;
    public invalidAddress: boolean;
    public spouseLine1: string;
    public spouseLine2: string;
    public spouseLine3: string;
    public spouseCity: string;
    public spouseState: string;
    public spousePostalCode: string;
    public spouseCountryCode: string;
    public spousePhone: string;
    public spouseAltPhone: string;
    public spouseEmail: string;
    public spouseOmniInd: boolean;
    public spouseParticipantNumber: string;
    public autoGenName: string;
    public adUsername: string;
    public isLocked: boolean;
    public responseStatus: ResponseStatus;
    public displayPhoneOptionInd: boolean;
    public preferredPhoneType: string;
    public preferredPhoneMethod: string;
    public healthPlanSponsors: string;

    public constructor(init?: Partial<GetPersonalInformationResponse>) { (Object as any).assign(this, init); }
}

export class GetBenefitsAccessSessionResponse
{
    public session: BenefitsAccessSession;
    public participantInfo: GetPersonalInformationResponse;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBenefitsAccessSessionResponse>) { (Object as any).assign(this, init); }
}

export class GetBenefitsAccessMenusResponse
{
    public headerMenu: HeaderMenu;
    public deniedPages: string[];
    public logonXml: string;
    public itrStatus: ParticipantItrStatus;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBenefitsAccessMenusResponse>) { (Object as any).assign(this, init); }
}

export class CheckBenefitSolverBannerStatusResponse
{
    public showEnrollmentBanner: boolean;
    public showPreEnrollmentBanner: boolean;
    public nextYearEligible: boolean;
    public enrollmentEndDate: string;
    public enrollmentDate: string;
    public jvUrl: string;

    public constructor(init?: Partial<CheckBenefitSolverBannerStatusResponse>) { (Object as any).assign(this, init); }
}

export class GetClaimsResponse
{
    public isParticipant: boolean;
    public claims: string[];
    public role: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetClaimsResponse>) { (Object as any).assign(this, init); }
}

export class ChangePasswordResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ChangePasswordResponse>) { (Object as any).assign(this, init); }
}

export class ChangeUsernameResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ChangeUsernameResponse>) { (Object as any).assign(this, init); }
}

export class GetAllDocumentsResponse
{
    public participantNumber: string;
    public documentTypes: ParticipantDocumentType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAllDocumentsResponse>) { (Object as any).assign(this, init); }
}

export class GetLatestQlacStatementIdResponse
{
    public guid: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetLatestQlacStatementIdResponse>) { (Object as any).assign(this, init); }
}

export class ErrHandlingResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ErrHandlingResponse>) { (Object as any).assign(this, init); }
}

export class GetEySearchResponse
{
    public pptIsFound: boolean;
    public participantNumber: string;
    public name: string;
    public firstName: string;
    public lastName: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEySearchResponse>) { (Object as any).assign(this, init); }
}

export class GetGoogleAnalyticsIdResponse
{
    public googleAnalyticsId: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetGoogleAnalyticsIdResponse>) { (Object as any).assign(this, init); }
}

export class GetHsaResponse
{
    public healthSavingsAccounts: HealthSavingsAccount[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetHsaResponse>) { (Object as any).assign(this, init); }
}

export class GetWellBeingResponse
{
    public vpIsQualified: boolean;
    public vpCoverageTier: string;
    public bfwIsQualified: boolean;
    public bfwCoverageTier: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetWellBeingResponse>) { (Object as any).assign(this, init); }
}

export class GetMedicareResponse
{
    public isEnrolled_ViaBen1: boolean;
    public amt_ViaBen1: number;
    public isEnrolled_ViaBen2: boolean;
    public amt_ViaBen2: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetMedicareResponse>) { (Object as any).assign(this, init); }
}

export class GetHealthPlansResponse
{
    public planElections: PlanElection[];
    public dateAsOf: string;
    public hasMedical: boolean;
    public quantumIsLive: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetHealthPlansResponse>) { (Object as any).assign(this, init); }
}

export class GetInvestmentsSummaryResponse
{
    public view: string;
    public previousBusinessDay: string;
    public dcBalance: boolean;
    public mppBalance: boolean;
    public lsriBalance: boolean;
    public investmentSummaryPlan: InvestmentSummaryPlanDetails[];
    public investmentSummary: InvestmentSummaryDetails[];
    public investmentSummaryTotal: InvestmentSummaryTotalDetails[];
    public investmentSummaryPlanTotal: number;
    public byplanGroupMPPName: string;
    public byplanGroupMPPAccountMgmt: string;
    public byPlanGroupOptionMPP: PlanGroupOptionDetails[];
    public byPlanGroupMPPBalanceTotal: number;
    public byPlanGroupMPPRatioTotal: number;
    public changeInvestmentsMPP?: boolean;
    public footer: string;
    public netRateofReturnDetailMPP: NetRateofReturnDetail[];
    public byplanGroupDCAName: string;
    public byplanGroupDCAAccountMgmt: string;
    public byPlanGroupOptionDCA: PlanGroupOptionDetails[];
    public byPlanGroupDCABalanceTotal: number;
    public byPlanGroupDCARatioTotal: number;
    public changeInvestmentsDCA?: boolean;
    public netRateofReturnDetailDCA: NetRateofReturnDetail[];
    public byplanGroupLSRIName: string;
    public byplanGroupLSRIAccountMgmt: string;
    public byPlanGroupOptionLSRI: PlanGroupOptionDetails[];
    public byPlanGroupLSRIBalanceTotal: number;
    public byPlanGroupLSRIRatioTotal: number;
    public changeInvestmentsLSRI?: boolean;
    public netRateofReturnDetailLSRI: NetRateofReturnDetail[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetInvestmentsSummaryResponse>) { (Object as any).assign(this, init); }
}

export class GetCmsFragmentResponse
{
    public fragment: CmsFragment;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetCmsFragmentResponse>) { (Object as any).assign(this, init); }
}

export class GetCmsFragmentCollectionResponse
{
    public fragments: CmsFragment[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetCmsFragmentCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetCppEligibilityResponse
{
    public clergyStatus: string;
    public cppEligible: string;
    public cppMsg: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetCppEligibilityResponse>) { (Object as any).assign(this, init); }
}

export class GetUmLifeOptionsResponse
{
    public benefitClassCode: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetUmLifeOptionsResponse>) { (Object as any).assign(this, init); }
}

export class GetLifeStageInvestmentOptionsResponse
{
    public lifeStageDisplay: boolean;
    public lifeStageAvailable: string;
    public lifeStageViewMsg: string;
    public profileDisplay: boolean;
    public mppLifeStageDisplay: boolean;
    public lsriLifeStageDisplay: boolean;
    public dcaLifeStageDisplay: boolean;
    public dcaLifeStageMsg: string;
    public participantDob: string;
    public dobDisplay: boolean;
    public riskTolerance: string;
    public riskToleranceDisplay: boolean;
    public socialSecurityDisplay: boolean;
    public socialSecurityValue: string;
    public mppExpectedBcd: boolean;
    public mppExpectedBcdKeyValue: string;
    public mppExpectedBcdValue: string;
    public mppIntention: boolean;
    public mppIntentionValue: string;
    public mppExpectedBcdOption: boolean;
    public mppExpectedAgeDisplay: boolean;
    public mppExpectedAge: string;
    public invTotalDisplay: boolean;
    public invTotalOptions: FundGroup[];
    public invTotalMessage: string;
    public invTotalSectionDisplay: boolean;
    public understandTargetDisplay: boolean;
    public targetAllocationsDisplay: boolean;
    public invMppDisplay: boolean;
    public invMppOptions: FundGroup[];
    public invLsriDisplay: boolean;
    public invLsriOptions: FundGroup[];
    public invDcaDisplay: boolean;
    public invDcaOptions: FundGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetLifeStageInvestmentOptionsResponse>) { (Object as any).assign(this, init); }
}

export class GetMultiFactorResponse
{
    public transactionResponse: LexisNexisTransactionResponse;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetMultiFactorResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonalInfoValidationResponse
{
    public participantNumber: string;
    public username: string;
    public firstName: string;
    public lastName: string;
    public name: string;
    public isRegistered: boolean;
    public phones: Phone[];
    public email: string;
    public contextToken: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetPersonalInfoValidationResponse>) { (Object as any).assign(this, init); }
}

export class CreateNewUserResponse
{
    public sessionId: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateNewUserResponse>) { (Object as any).assign(this, init); }
}

export class GetDuplicateUsernameResponse
{
    public isDuplicate: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetDuplicateUsernameResponse>) { (Object as any).assign(this, init); }
}

export class CheckOmniAvailableResponse
{
    public omniAvailable: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CheckOmniAvailableResponse>) { (Object as any).assign(this, init); }
}

export class GetLoginResponse
{
    public oracleLogin: OracleGetLogin;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetLoginResponse>) { (Object as any).assign(this, init); }
}

export class GetLsriInfoResponse
{
    public isEligible: boolean;
    public eligMsg: string;
    public partType: string;
    public stage: LsriStage;
    public stateList: OmniStateList;
    public personalInfo: GetPersonalInformationResponse;
    public balances: LsriFundingPlan[];
    public prLsriInfo: PrGetLsriInfoResponse;
    public oracleLsriElection: OracleLsriElection;
    public bankAccount: BankAccount;
    public taxOptions: TaxOptions;
    public messages: Messages[];
    public mppRolloverPercent: number;
    public disableSocSecDropdown: boolean;
    public showMppPage: boolean;
    public isMppAnnuityAvailable: boolean;
    public hasPendingMpp: boolean;
    public rothMsg: boolean;
    public caDesignated: boolean;
    public spendRateQlac: string;
    public spendRateNonQlac: string;
    public pendingTradeDate: string;
    public unavailableUntilDate: string;
    public holidayList: string[];
    public qlacCertificateGUID: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetLsriInfoResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLSRIAgeQlacBridgeResponse
{
    public output: OracleUpdateLSRIAgeQlacBridgeResponse;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateLSRIAgeQlacBridgeResponse>) { (Object as any).assign(this, init); }
}

export class RecalcOracleLsriElectionResponse
{
    public oracleLsriElection: OracleLsriElection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RecalcOracleLsriElectionResponse>) { (Object as any).assign(this, init); }
}

export class CallOracleResetProcessResponse
{
    public oracleLsriElection: OracleLsriElection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CallOracleResetProcessResponse>) { (Object as any).assign(this, init); }
}

export class CallOracleResetToPptVersionResponse
{
    public oracleLsriElection: OracleLsriElection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CallOracleResetToPptVersionResponse>) { (Object as any).assign(this, init); }
}

export class SubmitLsriElectionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SubmitLsriElectionResponse>) { (Object as any).assign(this, init); }
}

export class AddVisitedStepResponse
{
    public partDoesNotExist: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AddVisitedStepResponse>) { (Object as any).assign(this, init); }
}

export class ValidateBankAccountResponse
{
    public bankAcctValidationMsg: string;
    public bankOwnership: string;
    public denyCount: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ValidateBankAccountResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLsriBankAccountResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateLsriBankAccountResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLsriTaxOptionsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateLsriTaxOptionsResponse>) { (Object as any).assign(this, init); }
}

export class DeleteLsriResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteLsriResponse>) { (Object as any).assign(this, init); }
}

export class LsriAccountTransferResponse
{
    public isReceived: boolean;
    public tradeDate: string;
    public isVerified: boolean;
    public noPayments: boolean;
    public maxPaymentDate: string;
    public estPayment: number;
    public estPaymentDate: string;
    public responseStatus: ResponseStatus;
    public isPending: boolean;
    public sourceAccount: string;
    public targetAccount: string;
    public amount: number;
    public liquidate: string;

    public constructor(init?: Partial<LsriAccountTransferResponse>) { (Object as any).assign(this, init); }
}

export class GetMPPDistributionResponse
{
    public eligible: boolean;
    public eligibleMessage: string;
    public distPageElig: boolean;
    public mppBalance: number;
    public mppDistribution: string;
    public annuityDisplayBalance: boolean;
    public annuityDisplayButton: string;
    public rolloverCashDisplayBalance: boolean;
    public rolloverCashDisplayButton: string;
    public annuitizationBalance: number;
    public nonAnnuitizationBalance: number;
    public showMPPOption: boolean;
    public postGC2022: boolean;
    public explorerLifeStageDisplayButton: string;
    public pE607: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetMPPDistributionResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonalInfoResponse
{
    public personalInformation: GetPersonalInformationResponse;
    public stateList: OracleStateList;
    public countryList: OracleCountryList;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetPersonalInfoResponse>) { (Object as any).assign(this, init); }
}

export class GetPhonesResponse
{
    public phones: Phone[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetPhonesResponse>) { (Object as any).assign(this, init); }
}

export class UpdatePersonalInfoResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdatePersonalInfoResponse>) { (Object as any).assign(this, init); }
}

export class UpdateEyResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateEyResponse>) { (Object as any).assign(this, init); }
}

export class UpdateElectronicDeliveryResponse
{
    public success: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateElectronicDeliveryResponse>) { (Object as any).assign(this, init); }
}

export class GetBeneReviewResponse
{
    public showBeneMsg: boolean;

    public constructor(init?: Partial<GetBeneReviewResponse>) { (Object as any).assign(this, init); }
}

export class UpdateBeneReviewResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateBeneReviewResponse>) { (Object as any).assign(this, init); }
}

export class GetAssetAllocationResponse
{
    public allocations: Allocation[];
    public totalBal: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAssetAllocationResponse>) { (Object as any).assign(this, init); }
}

export class GetBalanceResponse
{
    public asOfDate: string;
    public balanceAmt: number;
    public installments: Installment[];
    public multiProfile: boolean;
    public plans: string;
    public ytdAmtChange: number;
    public showRetirement: boolean;
    public isRetired: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBalanceResponse>) { (Object as any).assign(this, init); }
}

export class GetInvestmentsResponse
{
    public investments: Investment[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetInvestmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetMonthlyBenefitResponse
{
    public label: string;
    public monthlyBenefitAmt: number;
    public moreBenMsg: boolean;
    public nextPayDate: string;
    public isQdro: boolean;
    public isDcbc: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetMonthlyBenefitResponse>) { (Object as any).assign(this, init); }
}

export class GetTransactionsResponse
{
    public transactions: Transaction[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTransactionsResponse>) { (Object as any).assign(this, init); }
}

export class GetPendingActionsResponse
{
    public pendingActions: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetPendingActionsResponse>) { (Object as any).assign(this, init); }
}

export class GetEyEligResponse
{
    public eyEligible: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEyEligResponse>) { (Object as any).assign(this, init); }
}

export class GetContributionPctResponse
{
    public contributionPct: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetContributionPctResponse>) { (Object as any).assign(this, init); }
}

export class GetBenefitProjectionResponse
{
    public ageAtRetirement: number;
    public estAmt: number;
    public estPct: number;
    public goalAmt: number;
    public goalPct: number;
    public asOfDate: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBenefitProjectionResponse>) { (Object as any).assign(this, init); }
}

export class GetSettingUpRetirementIncomeResponse
{
    public showSettingUpRetirementIncome: boolean;
    public isLayParticipant: boolean;
    public hasAccessRISPage: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSettingUpRetirementIncomeResponse>) { (Object as any).assign(this, init); }
}

export class GetSelfManagedRetirementIncomeResponse
{
    public isLsriPending: boolean;
    public distPageElig: boolean;
    public smriElig: string;
    public dbBenefitElig: boolean;
    public selfManagedRetirementMessage: SelfManagedRetirementMessage[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSelfManagedRetirementIncomeResponse>) { (Object as any).assign(this, init); }
}

export class BeginSessionResponse
{
    public endPoint: string;
    public organizationId: string;
    public threatMetrixSessionId: string;
    public webSessionId: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BeginSessionResponse>) { (Object as any).assign(this, init); }
}

export class KeepSessionAliveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<KeepSessionAliveResponse>) { (Object as any).assign(this, init); }
}

export class VerifySessionResponse
{
    public success: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<VerifySessionResponse>) { (Object as any).assign(this, init); }
}

export class GetThreatMetrixParametersResponse
{
    public endPoint: string;
    public organizationId: string;
    public sessionId: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetThreatMetrixParametersResponse>) { (Object as any).assign(this, init); }
}

export class UnlockResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnlockResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

export class VerifyLast4Ssn implements IReturn<VerifyLast4SsnResponse>
{
    public ssn: string;

    public constructor(init?: Partial<VerifyLast4Ssn>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyLast4Ssn'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new VerifyLast4SsnResponse(); }
}

export class AccountInfo implements IReturn<AccountOwnershipResponse>
{
    public isInternal: boolean;
    public username: string;
    public rtn: string;
    public accountNo: string;
    public customerPresent: string;
    public amount: string;
    public denyNsf: string;
    public country: string;
    public bankOwnershipScore: string;
    public pageName: string;
    public participantNum: string;
    public bankname: string;
    public accountType: string;
    public accountOwner: Accountowner;

    public constructor(init?: Partial<AccountInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AccountInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AccountOwnershipResponse(); }
}

export class LogActivity implements IReturn<LogActivityResponse>
{
    public sessionId: string;
    public application: string;
    public ipAddress: string;
    public partNumber: string;
    public username: string;
    public userType: string;
    public page: string;

    public constructor(init?: Partial<LogActivity>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LogActivity'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LogActivityResponse(); }
}

// @Route("/Redirect/{Link}")
// @Route("/Redirect/{Link}/{Source}")
export class ExternalLink
{
    public link: string;
    public source: string;

    public constructor(init?: Partial<ExternalLink>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExternalLink'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetApplicationTimeout implements IReturn<GetApplicationTimeoutResponse>
{

    public constructor(init?: Partial<GetApplicationTimeout>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplicationTimeout'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetApplicationTimeoutResponse(); }
}

// @Route("/GenerateAuthKeys")
export class GenerateNewAuthKeys implements IReturn<GenerateNewAuthKeysResponse>
{

    public constructor(init?: Partial<GenerateNewAuthKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateNewAuthKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateNewAuthKeysResponse(); }
}

// @Route("/GetAuthPhones")
export class GetAuthPhones implements IReturn<GetAuthPhonesResponse>
{

    public constructor(init?: Partial<GetAuthPhones>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAuthPhones'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAuthPhonesResponse(); }
}

export class GetAuthStatus implements IReturn<GetAuthStatusResponse>
{

    public constructor(init?: Partial<GetAuthStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAuthStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAuthStatusResponse(); }
}

export class SendAuthCode implements IReturn<SendAuthCodeResponse>
{
    public phoneType: PhoneType;
    public deliveryMethod: AuthentifyDeliveryMethod;
    public challengeType: ChallengeType;
    public page: string;

    public constructor(init?: Partial<SendAuthCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendAuthCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendAuthCodeResponse(); }
}

export class ValidateCode implements IReturn<ValidateCodeResponse>
{
    public code: string;

    public constructor(init?: Partial<ValidateCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateCodeResponse(); }
}

// @Route("/SSO/BapInbound")
export class BenefitsAccessPlanSponsorsSso implements IReturnVoid
{
    public token: string;
    public contextToken: string;
    public participantId: string;

    public constructor(init?: Partial<BenefitsAccessPlanSponsorsSso>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BenefitsAccessPlanSponsorsSso'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBenefitsAccessPlanSponsorsSsoUserInfo implements IReturn<GetBenefitsAccessPlanSponsorsSsoUserInfoResponse>
{

    public constructor(init?: Partial<GetBenefitsAccessPlanSponsorsSsoUserInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefitsAccessPlanSponsorsSsoUserInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBenefitsAccessPlanSponsorsSsoUserInfoResponse(); }
}

// @Route("/BenefitsAccessSso")
export class BenefitsAccessSso
{
    public page: string;
    public contextToken: string;

    public constructor(init?: Partial<BenefitsAccessSso>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BenefitsAccessSso'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBenefitsAccessSession implements IReturn<GetBenefitsAccessSessionResponse>
{

    public constructor(init?: Partial<GetBenefitsAccessSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefitsAccessSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBenefitsAccessSessionResponse(); }
}

// @Route("/BenefitsAccessMenus")
export class GetBenefitsAccessMenus implements IReturn<GetBenefitsAccessMenusResponse>
{
    public isGateway: boolean;
    public view: string;
    public planId: string;

    public constructor(init?: Partial<GetBenefitsAccessMenus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefitsAccessMenus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBenefitsAccessMenusResponse(); }
}

export class SetShowLifeAndDisability implements IReturnVoid
{

    public constructor(init?: Partial<SetShowLifeAndDisability>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetShowLifeAndDisability'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SetShowWellBeing implements IReturnVoid
{

    public constructor(init?: Partial<SetShowWellBeing>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetShowWellBeing'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SetShowHealth implements IReturnVoid
{

    public constructor(init?: Partial<SetShowHealth>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetShowHealth'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class CheckBenefitSolverBannerStatus implements IReturn<CheckBenefitSolverBannerStatusResponse>
{

    public constructor(init?: Partial<CheckBenefitSolverBannerStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckBenefitSolverBannerStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckBenefitSolverBannerStatusResponse(); }
}

export class GetClaims implements IReturn<GetClaimsResponse>
{

    public constructor(init?: Partial<GetClaims>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClaims'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClaimsResponse(); }
}

// @Route("/ChangePassword")
export class ChangePassword implements IReturn<ChangePasswordResponse>
{
    public username: string;
    public newPassword: string;
    public passwordOrigType: PasswordOrigType;

    public constructor(init?: Partial<ChangePassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangePassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChangePasswordResponse(); }
}

// @Route("/ChangeUsername")
export class ChangeUsername implements IReturn<ChangeUsernameResponse>
{
    public newUsername: string;

    public constructor(init?: Partial<ChangeUsername>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangeUsername'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChangeUsernameResponse(); }
}

export class GetAllDocuments implements IReturn<GetAllDocumentsResponse>, IHasBearerToken
{
    public bearerToken: string;

    public constructor(init?: Partial<GetAllDocuments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllDocuments'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAllDocumentsResponse(); }
}

export class GetLatestQlacStatementId implements IReturn<GetLatestQlacStatementIdResponse>
{

    public constructor(init?: Partial<GetLatestQlacStatementId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLatestQlacStatementId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLatestQlacStatementIdResponse(); }
}

// @Route("/ErrHandling")
export class ErrHandling implements IReturn<ErrHandlingResponse>
{
    public page: string;
    public message: string;

    public constructor(init?: Partial<ErrHandling>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ErrHandling'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ErrHandlingResponse(); }
}

// @Route("/EySearch")
export class GetEySearch implements IReturn<GetEySearchResponse>
{
    public searchValue: string;

    public constructor(init?: Partial<GetEySearch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEySearch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEySearchResponse(); }
}

// @Route("/GetAnalyticsId")
export class GetGoogleAnalyticsId implements IReturn<GetGoogleAnalyticsIdResponse>
{

    public constructor(init?: Partial<GetGoogleAnalyticsId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleAnalyticsId'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGoogleAnalyticsIdResponse(); }
}

export class GetHealthStatus implements IReturn<boolean>
{
    public participantNumber: string;

    public constructor(init?: Partial<GetHealthStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHealthStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return false; }
}

// @Route("/GetHsa")
export class GetHsa implements IReturn<GetHsaResponse>
{

    public constructor(init?: Partial<GetHsa>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHsa'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetHsaResponse(); }
}

// @Route("/GetWellBeing")
export class GetWellBeing implements IReturn<GetWellBeingResponse>
{

    public constructor(init?: Partial<GetWellBeing>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWellBeing'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWellBeingResponse(); }
}

// @Route("/GetMedicare")
export class GetMedicare implements IReturn<GetMedicareResponse>
{

    public constructor(init?: Partial<GetMedicare>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMedicare'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMedicareResponse(); }
}

// @Route("/GetHealthPlans")
export class GetHealthPlans implements IReturn<GetHealthPlansResponse>
{

    public constructor(init?: Partial<GetHealthPlans>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHealthPlans'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetHealthPlansResponse(); }
}

export class GetInvestmentsSummary implements IReturn<GetInvestmentsSummaryResponse>
{
    public planId: string;
    public partId: string;
    public type: string;

    public constructor(init?: Partial<GetInvestmentsSummary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestmentsSummary'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetInvestmentsSummaryResponse(); }
}

export class GetCmsFragment implements IReturn<GetCmsFragmentResponse>
{
    public path: string;
    public alias: string;

    public constructor(init?: Partial<GetCmsFragment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCmsFragment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCmsFragmentResponse(); }
}

export class GetCmsFragmentCollection implements IReturn<GetCmsFragmentCollectionResponse>
{
    public path: string;
    public alias: string;

    public constructor(init?: Partial<GetCmsFragmentCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCmsFragmentCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCmsFragmentCollectionResponse(); }
}

// @Route("/GetCppElig")
export class GetCppEligibility implements IReturn<GetCppEligibilityResponse>
{

    public constructor(init?: Partial<GetCppEligibility>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCppEligibility'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCppEligibilityResponse(); }
}

// @Route("/GetUmLifeOptions")
export class GetUmLifeOptions implements IReturn<GetUmLifeOptionsResponse>
{

    public constructor(init?: Partial<GetUmLifeOptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUmLifeOptions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUmLifeOptionsResponse(); }
}

// @Route("/GetLifeStageInvestmentOptions")
export class GetLifeStageInvestmentOptions implements IReturn<GetLifeStageInvestmentOptionsResponse>
{

    public constructor(init?: Partial<GetLifeStageInvestmentOptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLifeStageInvestmentOptions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLifeStageInvestmentOptionsResponse(); }
}

// @Route("/GetMultiFactorChallenge")
export class GetMultiFactorChallenge implements IReturn<GetMultiFactorResponse>
{
    public participant: LexisNexisParticipant;

    public constructor(init?: Partial<GetMultiFactorChallenge>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMultiFactorChallenge'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiFactorResponse(); }
}

// @Route("/GetOTPValidation")
export class GetOTPValidation implements IReturn<GetMultiFactorResponse>
{
    public otpResponse: LexisNexisOTP;
    public participant: LexisNexisParticipant;

    public constructor(init?: Partial<GetOTPValidation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOTPValidation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiFactorResponse(); }
}

// @Route("/GetKBAValidation")
export class GetKBAValidation implements IReturn<GetMultiFactorResponse>
{
    public kbaResponse: LexisNexisKBA;
    public participant: LexisNexisParticipant;

    public constructor(init?: Partial<GetKBAValidation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKBAValidation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiFactorResponse(); }
}

// @Route("/GetPersonalInfoValidation")
export class GetPersonalInfoValidation implements IReturn<GetPersonalInfoValidationResponse>
{
    public requestType: RequestType;
    public lastName: string;
    public ssn: string;
    public birthday: string;
    public threatMetrixSessionId: string;

    public constructor(init?: Partial<GetPersonalInfoValidation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPersonalInfoValidation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPersonalInfoValidationResponse(); }
}

// @Route("/CreateNewUser")
export class CreateNewUser implements IReturn<CreateNewUserResponse>
{
    public username: string;
    public password: string;
    public email: string;

    public constructor(init?: Partial<CreateNewUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateNewUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateNewUserResponse(); }
}

// @Route("/GetDuplicateUsernameValidation")
export class GetDuplicateUsernameValidation implements IReturn<GetDuplicateUsernameResponse>
{
    public username: string;

    public constructor(init?: Partial<GetDuplicateUsernameValidation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDuplicateUsernameValidation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDuplicateUsernameResponse(); }
}

export class CheckOmniAvailable implements IReturn<CheckOmniAvailableResponse>
{

    public constructor(init?: Partial<CheckOmniAvailable>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckOmniAvailable'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckOmniAvailableResponse(); }
}

export class GetLogin implements IReturn<GetLoginResponse>
{
    public username: string;
    public sessionId: string;
    public noCached: boolean;

    public constructor(init?: Partial<GetLogin>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLogin'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLoginResponse(); }
}

export class Logoff implements IReturnVoid
{
    public preserveCache: boolean;

    public constructor(init?: Partial<Logoff>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Logoff'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/GetLsriInfo")
export class GetLsriInfo implements IReturn<GetLsriInfoResponse>
{
    public stage?: LsriStage;
    public socSecStatus?: LsriSocSecStatus;
    public socSecChangedToNo: boolean;
    public socSecEstAmt?: number;

    public constructor(init?: Partial<GetLsriInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLsriInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLsriInfoResponse(); }
}

export class UpdateLSRIAgeQlacBridge implements IReturn<UpdateLSRIAgeQlacBridgeResponse>
{
    public scenario: string;
    public lsriPartId: number;
    public qlacFlag: boolean;
    public bridgeFlag: boolean;
    public selectedSSAge: number;

    public constructor(init?: Partial<UpdateLSRIAgeQlacBridge>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLSRIAgeQlacBridge'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateLSRIAgeQlacBridgeResponse(); }
}

// @Route("/RecalcOracleLsriElection")
export class RecalcOracleLsriElection implements IReturn<RecalcOracleLsriElectionResponse>
{
    public lsriPartId: number;
    public includeMpp: boolean;
    public commencementDate: string;
    public dcAmount: number;

    public constructor(init?: Partial<RecalcOracleLsriElection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RecalcOracleLsriElection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RecalcOracleLsriElectionResponse(); }
}

// @Route("/CallOracleResetProcess")
export class CallOracleResetProcess implements IReturn<CallOracleResetProcessResponse>
{
    public lsriPartId: number;

    public constructor(init?: Partial<CallOracleResetProcess>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CallOracleResetProcess'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CallOracleResetProcessResponse(); }
}

// @Route("/CallOracleResetToPptVersion")
export class CallOracleResetToPptVersion implements IReturn<CallOracleResetToPptVersionResponse>
{
    public lsriPartId: number;

    public constructor(init?: Partial<CallOracleResetToPptVersion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CallOracleResetToPptVersion'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CallOracleResetToPptVersionResponse(); }
}

// @Route("/SubmitLsriElection")
export class SubmitLsriElection implements IReturn<SubmitLsriElectionResponse>
{
    public oracleCalculation: OracleCalculation;
    public selectedPaymentDate: string;
    public bankInfo: BankAccount;
    public taxOptions: TaxOptions;
    public partType: string;
    public caDesignated: boolean;
    public mppRolloverPercent: number;
    public lsriPartId: number;
    public hasRoth: boolean;

    public constructor(init?: Partial<SubmitLsriElection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubmitLsriElection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SubmitLsriElectionResponse(); }
}

// @Route("/AddVisitedStep")
export class AddVisitedStep implements IReturn<AddVisitedStepResponse>
{
    public step: string;
    public lsriPartId: number;

    public constructor(init?: Partial<AddVisitedStep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddVisitedStep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddVisitedStepResponse(); }
}

// @Route("/ValidateBankAccount")
export class ValidateBankAccount implements IReturn<ValidateBankAccountResponse>
{
    public bankAccount: BankAccount;

    public constructor(init?: Partial<ValidateBankAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateBankAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateBankAccountResponse(); }
}

// @Route("/UpdateLsriBankAccount")
export class UpdateLsriBankAccount implements IReturn<UpdateLsriBankAccountResponse>
{
    public bankAccount: BankAccount;

    public constructor(init?: Partial<UpdateLsriBankAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLsriBankAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateLsriBankAccountResponse(); }
}

// @Route("/UpdateLsriTaxOptions")
export class UpdateLsriTaxOptions implements IReturn<UpdateLsriTaxOptionsResponse>
{
    public taxOptions: TaxOptions;

    public constructor(init?: Partial<UpdateLsriTaxOptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLsriTaxOptions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateLsriTaxOptionsResponse(); }
}

// @Route("/DeleteLsri")
export class DeleteLsri implements IReturn<DeleteLsriResponse>
{
    public lsriPartId: number;

    public constructor(init?: Partial<DeleteLsri>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteLsri'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteLsriResponse(); }
}

// @Route("/LsriAccountTransfer")
export class LsriAccountTransfer implements IReturn<LsriAccountTransferResponse>
{
    public isSubmit: boolean;
    public sourceAccount: string;
    public targetAccount: string;
    public amount: number;
    public liquidate: string;
    public isPending: boolean;

    public constructor(init?: Partial<LsriAccountTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LsriAccountTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LsriAccountTransferResponse(); }
}

// @Route("/GetMPPDistribution")
export class GetMPPDistribution implements IReturn<GetMPPDistributionResponse>
{

    public constructor(init?: Partial<GetMPPDistribution>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMPPDistribution'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMPPDistributionResponse(); }
}

// @Route("/GetPersonalInfo")
export class GetPersonalInfo implements IReturn<GetPersonalInfoResponse>
{

    public constructor(init?: Partial<GetPersonalInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPersonalInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPersonalInfoResponse(); }
}

export class GetPersonalInformation implements IReturn<GetPersonalInformationResponse>, IHasBearerToken
{
    public searchId: string;
    public bearerToken: string;

    public constructor(init?: Partial<GetPersonalInformation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPersonalInformation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPersonalInformationResponse(); }
}

// @Route("/GetPhones")
export class GetPhones implements IReturn<GetPhonesResponse>
{
    public searchId: string;

    public constructor(init?: Partial<GetPhones>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPhones'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPhonesResponse(); }
}

// @Route("/UpdatePersonalInfo")
export class UpdatePersonalInfo implements IReturn<UpdatePersonalInfoResponse>
{
    public streetAddress1: string;
    public streetAddress2: string;
    public streetAddress3: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public countryCode: string;
    public countryName: string;
    public email: string;
    public phone: string;
    public altPhone: string;
    public textPhone: string;
    public participantNumber: string;
    public spouseSsn: string;
    public updateSpouseAddr: boolean;

    public constructor(init?: Partial<UpdatePersonalInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePersonalInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatePersonalInfoResponse(); }
}

// @Route("/UpdateEy")
export class UpdateEy implements IReturn<UpdateEyResponse>
{
    public isAuthorized: boolean;

    public constructor(init?: Partial<UpdateEy>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEy'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEyResponse(); }
}

export class UpdateElectronicDelivery implements IReturn<UpdateElectronicDeliveryResponse>, IHasBearerToken
{
    public bearerToken: string;
    public participantNumber: string;
    public ssn: string;
    public isElectronicDelivery: boolean;
    public updatedByUser: string;

    public constructor(init?: Partial<UpdateElectronicDelivery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateElectronicDelivery'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateElectronicDeliveryResponse(); }
}

// @Route("/GetBeneReview")
export class GetBeneReview implements IReturn<GetBeneReviewResponse>
{
    public username: string;

    public constructor(init?: Partial<GetBeneReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBeneReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBeneReviewResponse(); }
}

// @Route("/UpdateBeneReview")
export class UpdateBeneReview implements IReturn<UpdateBeneReviewResponse>
{
    public code: string;

    public constructor(init?: Partial<UpdateBeneReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateBeneReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateBeneReviewResponse(); }
}

// @Route("/AssetAllocation")
export class GetAssetAllocation implements IReturn<GetAssetAllocationResponse>
{

    public constructor(init?: Partial<GetAssetAllocation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAssetAllocation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAssetAllocationResponse(); }
}

// @Route("/Balance")
export class GetBalance implements IReturn<GetBalanceResponse>
{

    public constructor(init?: Partial<GetBalance>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBalance'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBalanceResponse(); }
}

// @Route("/Investments")
export class GetInvestments implements IReturn<GetInvestmentsResponse>
{

    public constructor(init?: Partial<GetInvestments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestments'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetInvestmentsResponse(); }
}

// @Route("/MonthlyBenefit")
export class GetMonthlyBenefit implements IReturn<GetMonthlyBenefitResponse>
{

    public constructor(init?: Partial<GetMonthlyBenefit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMonthlyBenefit'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMonthlyBenefitResponse(); }
}

// @Route("/Transactions")
export class GetTransactions implements IReturn<GetTransactionsResponse>
{

    public constructor(init?: Partial<GetTransactions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTransactions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTransactionsResponse(); }
}

// @Route("/PendingActions")
export class GetPendingActions implements IReturn<GetPendingActionsResponse>
{

    public constructor(init?: Partial<GetPendingActions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPendingActions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPendingActionsResponse(); }
}

// @Route("/GetEyElig")
export class GetEyElig implements IReturn<GetEyEligResponse>
{

    public constructor(init?: Partial<GetEyElig>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEyElig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEyEligResponse(); }
}

// @Route("/ContributionPct")
export class GetContributionPct implements IReturn<GetContributionPctResponse>
{

    public constructor(init?: Partial<GetContributionPct>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContributionPct'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetContributionPctResponse(); }
}

// @Route("/BenefitProjection")
export class GetBenefitProjection implements IReturn<GetBenefitProjectionResponse>
{

    public constructor(init?: Partial<GetBenefitProjection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBenefitProjection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBenefitProjectionResponse(); }
}

// @Route("/SettingUpRetirementIncome")
export class GetSettingUpRetirementIncome implements IReturn<GetSettingUpRetirementIncomeResponse>
{

    public constructor(init?: Partial<GetSettingUpRetirementIncome>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSettingUpRetirementIncome'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSettingUpRetirementIncomeResponse(); }
}

export class GetSelfManagedRetirementIncome implements IReturn<GetSelfManagedRetirementIncomeResponse>
{

    public constructor(init?: Partial<GetSelfManagedRetirementIncome>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSelfManagedRetirementIncome'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSelfManagedRetirementIncomeResponse(); }
}

// @Route("/SSO/{ServiceProvider}")
// @Route("/SSO/{ServiceProvider}/{Source}")
export class ServiceProviderSso extends ExternalSso
{

    public constructor(init?: Partial<ServiceProviderSso>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'ServiceProviderSso'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BeginSession implements IReturn<BeginSessionResponse>
{

    public constructor(init?: Partial<BeginSession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BeginSession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BeginSessionResponse(); }
}

export class KeepSessionAlive implements IReturn<KeepSessionAliveResponse>
{

    public constructor(init?: Partial<KeepSessionAlive>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KeepSessionAlive'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new KeepSessionAliveResponse(); }
}

export class VerifySession implements IReturn<VerifySessionResponse>
{
    public tokenHash: string;
    public contextToken: string;
    public ipAddress: string;

    public constructor(init?: Partial<VerifySession>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifySession'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new VerifySessionResponse(); }
}

// @Route("/GetParameters")
export class GetThreatMetrixParameters implements IReturn<GetThreatMetrixParametersResponse>
{
    public bearerToken: string;

    public constructor(init?: Partial<GetThreatMetrixParameters>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetThreatMetrixParameters'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetThreatMetrixParametersResponse(); }
}

// @Route("/Unlock")
export class Unlock implements IReturn<UnlockResponse>
{

    public constructor(init?: Partial<Unlock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Unlock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnlockResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

