import * as React from 'react';
import { MenuLink } from '../../definitions/Gateway.dtos';
import { ItemWithArrow } from './ItemWithArrow';

const DesktopSubMenu = (props: { items?: (MenuLink)[], isGateway: boolean; hideOverlayMenu: Function}) => {
    const { items, isGateway } = props;

    if (!items) {
        return null;
    } else {
        return (
            <ul>
                {items.map((item, i) => {
                    const { link, text, gatewayLink, externalLink} = item;

                    return <li key={i}>
                                <ItemWithArrow link={link} text={text} isGatewayLink={gatewayLink} isGateway={isGateway} isExternalLink={externalLink} hasParent hideOverlayMenu={() => { props.hideOverlayMenu(); }} />
                           </li>
                })}
            </ul>
        )
    }
}

export { DesktopSubMenu }