import { faBusinessTime, faChalkboardTeacher, faClipboardCheck, faHandsHelping, faTrophy, faWalking, faWeight } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react'
import { useEffect, useRef, useState } from 'react';
import { ServiceProvider } from '../definitions/Gateway.dtos';
import { hasGatewayClaim } from '../functions/hasGatewayClaim';
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext';
import { ExternalLinkModal } from './ExternalLinkModal';
import { SamlSelfPostForm } from './SamlSelfPostForm';
import { SubMenu, SubMenuLink } from './SubMenu';

const SubMenuWellBeing : React.FC = () => {
    const { 
        userInfo,
        wellBeing,
        gateway
    } = useGatewayNavigationContext()!;
    const { apiRootUrl, claims } = gateway
    const username = userInfo.username;
    const { hasMedical, hasVp } = wellBeing;

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const [serviceProvider, setServiceProvider] = useState(ServiceProvider.None);

    const hasWellBeingView = hasGatewayClaim(claims, "WellBeing.View")
    const hasWellBeingAccess = hasGatewayClaim(claims, "WellBeing.Access")

    const formRef = useRef<HTMLFormElement>(null);

    if (!hasWellBeingView) {
        return <></>;
    }
    useEffect(() => {
        if (serviceProvider === ServiceProvider.None)
            return;
        else if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
            setServiceProvider(ServiceProvider.None);
        }
        else
            setShowingSsoModal(true);

    }, [serviceProvider]);

    const hideModal = () => {
        setShowingSsoModal(false);
        setServiceProvider(ServiceProvider.None);
    }

    const trackAndRedirect = (target: string) => {
        window.open(`${apiRootUrl}/Redirect/${encodeURIComponent(target)}/m`, "_blank");
    }

    return (<>
        <SubMenu title="Well-being Shortcuts" minorTitle="(Links open in a new window)">
            {hasVp && <SubMenuLink linkType="external" ClickHandler={() => { setServiceProvider(ServiceProvider.VirginPulse); }} iconName={faWalking} text="Virgin Pulse" tabletText={<>Virgin<br />Pulse</>} disabled={!hasWellBeingAccess} />}
            {hasMedical && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://go.omadahealth.com/wespath")} iconName={faClipboardCheck} text="Disease Prevention" desktopText={<>Disease<br />Prevention</>} />}
            {hasMedical && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://go.omadahealth.com/wespath")} iconName={faBusinessTime} text="Diabetes Management" desktopText={<>Diabetes<br />Management</>} />}
            {hasMedical && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://www.liveandworkwell.com?pin=HealthFlex&redirectURL=/content/en/member.html")} iconName={faHandsHelping} text="EAP" />}
            {hasMedical && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://www.virginpulse.com/login")} iconName={faChalkboardTeacher} text="Health Coaching" desktopText={<>Health<br />Coaching</>} />}
            {hasVp && <SubMenuLink linkType="external" ClickHandler={() => { setServiceProvider(ServiceProvider.VirginPulse); }} iconName={faTrophy} text="Redeem Pulse-Cash" desktopText={<>Redeem<br />Pulse-Cash</>} disabled={!hasWellBeingAccess} />}
            {hasMedical && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://www.weightwatchers.com/us/HealthFlex")} iconName={faWeight} text="Weight Watchers" desktopText={<>Weight<br />Watchers</>} />}
        </SubMenu>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider}  source="m" />
        <ExternalLinkModal isShowing={isShowingSsoModal} hideModal={() => hideModal()} serviceProvider={serviceProvider} username={username} referrer="m"/> 
    </>);
}

export { SubMenuWellBeing }