export function prettyUrl(urlRoot: string, urlPath: string, between?: string) : string {
    while (urlRoot.slice(-1) === "/") {
        urlRoot = urlRoot.slice(0, -1);
    }

    while (urlPath.slice(0, 1) === "/") {
        urlPath = urlPath.slice(1);
    }

    if (!between) {
        return `${urlRoot}/${urlPath}`;    
    } else {
        while (between.slice(-1) === "/") {
            between = between.slice(0, -1);
        }
    
        while (between.slice(0, 1) === "/") {
            between = between.slice(1);
        }
        
        return `${urlRoot}/${between}/${urlPath}`;    
    }    
}