import { faBriefcaseMedical, faEye, faFileMedical, faMoneyCheckAlt, faPrescriptionBottle, faTooth } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { hasGatewayClaim } from '../functions/hasGatewayClaim';
import { PlanType, ServiceProvider } from '../definitions/Gateway.dtos';
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext';
import { ExternalLinkModal } from './ExternalLinkModal';
import { SubMenu, SubMenuLink } from './SubMenu';
import { SamlSelfPostForm } from './SamlSelfPostForm';
import { GatewayPath } from '../definitions/GatewayPath';

interface SubMenuHealthProps {
    HideMenu?: Function;
}

const SubMenuHealth = (props: SubMenuHealthProps) => {
    const { HideMenu } = props;

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const [serviceProvider, setServiceProvider] = useState(ServiceProvider.None);

    const { 
        userInfo,
        health,
        gateway
    } = useGatewayNavigationContext()!;

    const { apiRootUrl, claims } = gateway
    const username = userInfo.username

    const { hasHSA, planElections } = health

    const medical = planElections?.find(p => p.type === PlanType.MEDICAL);

    const hasMedical = !!medical;

    const hasBCBS = hasMedical && medical?.plan.startsWith("BCBS");
    const hasUHC = hasMedical && medical?.plan.startsWith("UHC");
    
    const hasPrescription = !!planElections?.find(p => p.type === PlanType.PHARMACY);
    const hasDental = !!planElections?.find(p => p.type === PlanType.DENTAL);
    const hasVision = !!planElections?.find(p => p.type === PlanType.VISION);

    const hasHealthAccess = hasGatewayClaim(claims, "Health.Access")
    const hasHealthView = hasGatewayClaim(claims, "Health.View")

    const formRef = useRef<HTMLFormElement>(null);

    if (!hasHealthView) {
        return null;
    }

    useEffect(() => {
        if (serviceProvider === ServiceProvider.None)
            return;
        else if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
            setServiceProvider(ServiceProvider.None);
        }
        else 
            setShowingSsoModal(true);        

    }, [serviceProvider]);

    const hideModal = () => {
        setShowingSsoModal(false);
        setServiceProvider(ServiceProvider.None);
    }

    const trackAndRedirect = (target: string) => {
        window.open(`${apiRootUrl}/Redirect/${encodeURIComponent(target)}/m`, "_blank");
    }

    const showMenu = hasHSA || hasMedical || hasDental || hasVision || hasPrescription;

    return (<>
        {showMenu && <>
            <SubMenu title="Health Shortcuts" minorTitle="(Links open in a new window)">
                {hasHSA && <SubMenuLink linkType="external" iconName={faMoneyCheckAlt} ClickHandler={() => setServiceProvider(ServiceProvider.WageWorks)} text="Health & Reimbursement Accounts" desktopText={<>Reimbursement<br />Accounts</>} disabled={!hasHealthAccess} suppressIconOnSmallScreen />}
                {hasBCBS && <SubMenuLink linkType="external" iconName={faBriefcaseMedical} ClickHandler={() => setServiceProvider(ServiceProvider.BCBS)} text="Medical & Behavioral Health" desktopText={<>Medical &<br />Behavioral</>} disabled={!hasHealthAccess} suppressIconOnSmallScreen /> }
                {hasUHC && <SubMenuLink linkType="external" iconName={faBriefcaseMedical} ClickHandler={() => setServiceProvider(ServiceProvider.UHC)} text="Medical & Behavioral Health" desktopText={<>Medical &<br />Behavioral</>} disabled={!hasHealthAccess} suppressIconOnSmallScreen />}
                {hasPrescription && <SubMenuLink linkType="external" iconName={faPrescriptionBottle} ClickHandler={() => setServiceProvider(ServiceProvider.Optum)} text="Prescriptions" disabled={!hasHealthAccess} suppressIconOnSmallScreen />}
                {hasDental && <SubMenuLink linkType="external" iconName={faTooth} ClickHandler={() => setServiceProvider(ServiceProvider.Cigna)} text="Dental" disabled={!hasHealthAccess} suppressIconOnSmallScreen />}
                {hasVision && <SubMenuLink linkType="external" ClickHandler={() => trackAndRedirect("https://www.VSP.com/")} iconName={faEye} text="Vision" suppressIconOnSmallScreen />}
                <SubMenuLink to={GatewayPath.HealthFlexBenefits} iconName={faFileMedical} text="Manage My Benefits" desktopText={<>Manage <br /> Health Benefits</>} suppressIconOnSmallScreen hideMenuFunction={HideMenu} />
            </SubMenu>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="m" />
            <ExternalLinkModal isShowing={isShowingSsoModal} hideModal={() => hideModal()} serviceProvider={serviceProvider} username={username} referrer="m"/>
        </>}
    </>);

}

export { SubMenuHealth }