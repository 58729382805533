import * as React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useGatewayNavigationContext } from '../hooks/useGatewayNavigationContext'
import { prettyUrl } from "../functions/prettyUrl";

type Props = {
    to: string
    children: any
    className?: string
    navLink?: boolean
    activeClassName?: string
}

const GatewayLink = ({
    to,
    children,
    className,
    navLink,
    activeClassName
}: Props) => {
    const {
        currentPath,
        isGateway,
        rootUrl
    } = useGatewayNavigationContext()!.gateway;

    if (navLink) {
        if (isGateway) {
            return <NavLink to={to} className={className} activeClassName={activeClassName}>{children}</NavLink>
        } else {
            const finalClassName = `${className ?? ''}${(activeClassName && to === currentPath) ? ` ${activeClassName}` : ''}` 
            return <a href={prettyUrl(rootUrl, to)} className={finalClassName} >{children}</a>
        }
    } else {
        if (isGateway) {
            return <Link to={to} className={className}>{children}</Link>
        } else {
            return <a href={prettyUrl(rootUrl, to)} className={className} >{children}</a>
        }
    }
}

export {GatewayLink}