import * as React from "react";
import { DesktopMenuItem } from "./DesktopMenuItem";
import { Menu } from '../../definitions/Gateway.dtos';

const HeaderMenuColumn = (props: { columnNumber: number, menu: Menu, hideLinks: boolean, isGateway: boolean; hideOverlayMenu: Function}) => {
    const { menu, columnNumber, hideLinks, isGateway } = props;
    const itemsToRender = columnNumber
        ? menu.children.filter(item => item.column === props.columnNumber)
        : menu.children;

    return (
        <ul className="list-unstyled">
            {itemsToRender.map((item, i) => (
                <li key={i}>
                    <DesktopMenuItem
                        item={item}
                        childItems={item.children}
                        hideLinks={hideLinks}
                        isGateway={isGateway}
                        hideOverlayMenu={() => { props.hideOverlayMenu(); }}
                    />
                </li>
            ))}
        </ul>
    )
}

export { HeaderMenuColumn }