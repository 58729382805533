import * as React from 'react'
import { GatewayLink } from './GatewayLink'

type Props = {
    to: string
    text: string
    isActive?: boolean
}

const HeaderLink = ({
    to,
    text,
    isActive
}: Props) => (
    <GatewayLink to={to} className={`nav-link ${isActive ? 'active' : ''}`} activeClassName="active" navLink>{text}</GatewayLink>
)

export { HeaderLink }

