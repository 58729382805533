import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Menu } from '../../definitions/Gateway.dtos';
import { HeaderMenuColumn } from "./HeaderMenuColumn";



const DesktopOverlay = (props: { menu: Menu; hideLinks: boolean; isGateway: boolean; hideOverlayMenu: Function}) => {
    const { menu, hideLinks, isGateway } = props;
    return <>
        <Row className="retirement-menu-dropdown-lg">
            <Col xs={12} md={6} className="text-nowrap">
                <HeaderMenuColumn menu={menu} columnNumber={1} hideLinks={hideLinks} isGateway={isGateway} hideOverlayMenu={() => { props.hideOverlayMenu(); }}/>
            </Col>
            <Col xs={12} md={6} className="text-nowrap">
                <HeaderMenuColumn menu={menu} columnNumber={2} hideLinks={hideLinks} isGateway={isGateway} hideOverlayMenu={() => { props.hideOverlayMenu(); }} />
            </Col>
        </Row>
    </>;
}

export { DesktopOverlay }