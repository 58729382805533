import * as React from 'react';
import { Link } from 'react-router-dom';

const MobileLink = (props: { link: string, text: string, isGateway: boolean; isGatewayLink: boolean; hasParent: boolean; hideOverlayMenu: Function }) => {
    const className = props.hasParent ? "top-link child" : "top-link";

    if (props.isGateway) {
        return <Link className={className} to={props.link} onClick={() => { props.hideOverlayMenu(); }}>{props.text}</Link>
    } else {
        return <a className={className} href={props.link}>{props.text}</a>
    }
    
};

export { MobileLink }